import InputFields from "../../components/common/InputField";
import Button from "../common/Button";
import { useForm } from "react-hook-form";
import { useState } from "react";
import Swal from "sweetalert2";
import { axiosUAMInstance } from "../../utils/axios";
import { convertFileToBase64 } from "../../utils/fileConversion";
import DscButton from "../form/Dscbutton";

const EditNodalDetail = ({
  nodalDetails,
  nodalOfficerId,
  entityUniqueId,
  toggleClose,
  entityType,
  fetchFormFields,
  update_url,
}: any) => {
  const [fileName, setFileName] = useState<string | undefined>("");
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [base64Data, setBase64Data] = useState<string>("");
  const [hexData, setHexData] = useState("");
  const [loader, setLoader] = useState<boolean>(false);

  const handleFileUpload = (file: File | null) => {
    setFileName(file?.name);
    if (file) {
      setIsFileUploaded(true);

      convertFileToBase64(
        file,
        (hex) => {
          setHexData(hex);
        },
        (base64) => {
          setBase64Data(base64);
        }
      );
    } else {
      setIsFileUploaded(false);
      setBase64Data(""); // Clear the base64 data if no file is uploaded
      setHexData(""); // Clear the hex data as well
    }
  };

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  console.log("nodaldetails", nodalDetails);
  const onSubmit = (data: any) => {
    setLoader(true);
    const formData = nodalDetails?.map((field: any) => {
      const value = field?.key
        ? field?.key === "dsc3" && base64Data
          ? base64Data
          : data[field?.key] || data[field?.key]
        : data[`defaultKey_${field?.label}`]; // Use a default key for fields with null key

      return {
        fieldId: field?.id,
        label: field?.label,
        sectionCode: "Nodal Details",
        value: value,
        key: field?.key || `defaultKey_${field?.label}`, // Assign a default key if null
      };
      // const value =
      //   field?.key === "dsc3" && base64Data
      //     ? base64Data
      //     : data[field?.key] || data[field?.key];

      // return {
      //   fieldId: field?.id,
      //   label: field?.label,
      //   sectionCode: "Nodal Details",
      //   value: value,
      //   key: field?.key,
      // };
    });
    console.log("form", formData);

    axiosUAMInstance
      .put(`${update_url}`, {
        entityUniqueId: entityUniqueId,
        formData: formData,
      })
      .then((response) => {
        setLoader(false);
        Swal.fire({
          icon: "success",
          title: "Saved",
          text: response.data.message || "Data has been successfully saved!",
        });
        fetchFormFields();
        toggleClose();
      })
      .catch((error: any) => {
        console.error("Error saving form data:", error);

        setLoader(false);
        const errorMessage =
          error?.response?.data?.message ||
          error?.response?.data?.detail?.error?.errorMessage ||
          "An error occurred while saving the data.";

        Swal.fire({
          icon: "error",
          title: "Failed to Save",
          text: errorMessage,
        });
      });
  };

  const renderInputField = (field: any) => {
    const { label, key, userInput } = field;
    const errorObj = errors[key || `defaultKey_${label}`];
    const errorMessage =
      errorObj && typeof errorObj.message === "string" ? errorObj.message : "";

    if (!key) {
      return (
        <>
          <InputFields
            {...register(key || `defaultKey_${label}`, {
              required: `${label} is required`,
            })}
            placeholder={`Enter ${label}`}
            variant="basic"
            width="350px"
            defaultValue={userInput}
          />
          {errorMessage && <p className="text-red-500">{errorMessage}</p>}
        </>
      );
    }

    switch (label) {
      case "Nodal Officer Email":
        return (
          <>
            <InputFields
              {...register(key, {
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Invalid email address",
                },
              })}
              placeholder="Enter Nodal Officer's email"
              variant="basic"
              width="350px"
              defaultValue={userInput}
            />
            {errorMessage && <p className="text-red-500">{errorMessage}</p>}
          </>
        );

      case "Nodal Officer Mobile Number":
        return (
          <>
            <InputFields
              {...register(key, {
                required: "Mobile number is required",
                pattern: {
                  value: /^\d{10}$/,
                  message: "Mobile number must be exactly 10 digits",
                },
              })}
              type="number"
              placeholder="Enter Nodal Officer's mobile number"
              variant="basic"
              width="350px"
              defaultValue={userInput}
            />
            {errorMessage && <p className="text-red-500">{errorMessage}</p>}
          </>
        );

      case "Nodal Officer First Name":
      case "Nodal Officer Last Name":
        return (
          <>
            <InputFields
              {...register(key, {
                required: `${label} is required`,
                minLength: {
                  value: 1,
                  message: `${label} must be at least 2 characters`,
                },
                maxLength: {
                  value: 50,
                  message: `${label} must not exceed 50 characters`,
                },
              })}
              placeholder={`Enter ${label}`}
              variant="basic"
              width="350px"
              defaultValue={userInput}
            />
            {errorMessage && <p className="text-red-500">{errorMessage}</p>}
          </>
        );

      case "Nodal Officer Middle Name":
        return (
          <>
            <InputFields
              {...register(key, {
                // required: `${label} is required`,
                minLength: {
                  value: 1,
                  message: `${label} must be at least 2 characters`,
                },
                maxLength: {
                  value: 50,
                  message: `${label} must not exceed 50 characters`,
                },
              })}
              placeholder={`Enter ${label}`}
              variant="basic"
              width="350px"
              defaultValue={userInput}
            />
            {errorMessage && <p className="text-red-500">{errorMessage}</p>}
          </>
        );

      default:
        return (
          <>
            {field?.key !== "dsc3" && (
              <>
                {" "}
                <InputFields
                  {...register(key || `defaultKey_${label}`, {
                    required: `${label} is required`,
                  })}
                  placeholder={`Enter ${label}`}
                  variant="basic"
                  width="350px"
                  defaultValue={userInput}
                />
                {errorMessage && <p className="text-red-500">{errorMessage}</p>}
              </>
            )}
          </>
        );
    }
  };

  return (
    <div className="mt-6 flex flex-col h-full justify-between">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="grid grid-cols-1 md:grid-cols-3 gap-4"
      >
        {nodalDetails?.map((field: any, index: number) => (
          <div key={index}>
            <label
              htmlFor={field.name}
              className="text-base font-normal text-gilroy-medium"
            >
              {field?.key !== "dsc3" && (
                <>
                  {" "}
                  {field?.label}
                  {field?.key !== "nodalMiddlename" && (
                    <>
                      {" "}
                      <span className="text-red-500">*</span>
                    </>
                  )}
                </>
              )}
            </label>
            <div className="mt-2">{renderInputField(field)}</div>
          </div>
        ))}
        <div className="col-span-1 md:col-span-3 flex justify-end items-center p-2 mt-10">
          <div onClick={toggleClose} className="mr-7 w-40">
            <Button
              type="button"
              label="cancel"
              className="h-10"
              variant="outline"
            />
          </div>
          <div className="w-40">
            <Button
              loader={loader}
              type="submit"
              label="Save"
              className="h-10"
              variant="basic"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditNodalDetail;
