import LoginPageIcon from "../assets/images/Login-bud.svg";
import MobileIcon from "../assets/images/MobileIcon.svg";
import InputFields from "../components/form/InputField";
import Button from "../components/form/Button";
import InputFieldPassword from "../components/form/InputFieldPassword";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Dscbutton from "../components/form/Dscbutton";
import { convertFileToBase64 } from "../utils/fileConversion";
import ForgetPasswordModel from "../components/common/ForgetPasswordModel";
import RegisterMailModel from "../components/common/RegisterMailModel";
import DscKeyLogin from "../components/form/DscKeyLogin";
import { useEffect } from "react";
import { axiosTraceIdInstance } from "../utils/axios";
import Swal from "sweetalert2";

const LoginPage = () => {
  const [isBlurred, setIsBlurred] = useState(false);
  const [show, setShow] = useState(true);
  const [loader, setLoader] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [base64Data, setBase64Data] = useState<string>("");
  const [hexData, setHexData] = useState("");
  const [showError, setShowError] = useState("");
  const [roles, setRoles] = useState<any>();
  const [dsc, setDsc] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string | undefined>("");
  const [responceData, setResponceData] = useState<any>();
  const [showForgetPasswordModel, setShowForgetPasswordModel] =
    useState<boolean>(false);
  const [showRegisterMailModel, setShowRegisterMailModel] =
    useState<boolean>(false);
  const [showNewpasswordModel, setShowNewpasswordModel] =
    useState<boolean>(true);
  const [showPasswordUpdateModel, setShowPasswordUpdateModel] =
    useState<boolean>(false);

  const [isDscSelected, setDscSelected] = useState<boolean>(false);
  const [dscCertificate, setDscCertificate] = useState<any>();

  const navigate = useNavigate();

  const isDscKeyAvbl = window.appConfig.REACT_APP_IS_DSC_KEY_AVBL;

  const NavigateToForgetPassword = () => {
    setShow(false);
    setShowForgetPasswordModel(true);
  };
  const CloseForgetPasswordModel = () => {
    setShowForgetPasswordModel(false);
  };
  const CloseForgetPasswordandshowLogin = () => {
    setShowForgetPasswordModel(false);
    setShow(true);
  };
  const CloseForgetPasswordAndshowRegisterMail = () => {
    setShowForgetPasswordModel(false);
    setShowRegisterMailModel(true);
  };

  const CloseRegisterMail = () => {
    setShowRegisterMailModel(false);
  };
  const CloseRegisterMainlandShowLoginModel = () => {
    setShowRegisterMailModel(false);
    setShow(true);
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const loginApiCall = async () => {
    setLoader(true);
    setError(false);
    axiosTraceIdInstance
      .post(`/adminauth/login`, {
        username: watch("email").replace(/\s+/g, ""),
        password: watch("password"),
      })
      .then((responce) => {
        setShowError("");
        setResponceData(responce);
        setRoles(responce?.data?.response?.role);
        setDsc(true);

        sessionStorage.setItem("firstName", responce?.data?.user?.firstName);
        sessionStorage.setItem("middleName", responce?.data?.user?.middleName);
        sessionStorage.setItem("lastName", responce?.data?.user?.lastName);
        sessionStorage.setItem("roles", responce?.data?.response?.role);
        sessionStorage.setItem("userId", responce?.data?.user?.id);
        sessionStorage.setItem("emailId", responce?.data?.user?.emailId);

        if (roles) {
          apicallDsc();
        }

        if (!roles) {
          setLoader(false);
        }

        setError(false);
      })
      .catch((error: any) => {
        const errorMessage =
          typeof error?.response?.data?.error === "string"
            ? error?.response?.data?.error
            : "Unkonw error occurred";
        setShowError(errorMessage);
        Swal.fire({
          icon: "error",
          text: errorMessage,
        });
        setLoader(false);
        setError(true);
      });
  };

  const handleClose = () => {
    setShow(false);
    setIsBlurred(false);
  };

  useEffect(() => {
    convertBase64DSC();
  }, [dscCertificate]);

  const convertBase64DSC = () => {};

  const apicallDsc = () => {
    setLoader(true);
    axiosTraceIdInstance
      .post(`/adminauth/mfa`, {
        username: watch("email").replace(/\s+/g, ""),
        dscCertificateFile:
          isDscKeyAvbl === "true" ? dscCertificate : base64Data,
      })
      .then((respose) => {
        setShowError("");
        setLoader(true);
        sessionStorage.setItem("reload", "true");
        sessionStorage.setItem(
          "access_token",
          responceData?.data?.response?.access_token
        );
        sessionStorage.setItem(
          "refresh_token",
          responceData?.data?.response?.refresh_token
        );
        sessionStorage.removeItem("refreshCount");
        setTimeout(() => {
          const rolesArray = roles;
          if (rolesArray.includes("dashboard-viewer-role")) {
            navigate("/dashboard");
          } else {
            if (
              rolesArray?.length === 1 &&
              rolesArray[0] === "notification-manager"
            ) {
              sessionStorage.setItem("notification-manager", "true");
            }
            rolesArray.forEach((role: string) => {
              switch (role) {
                case "approver-role":
                  navigate("/entitymaster/deposit");
                  break;
                case "cms-role":
                  navigate("/cms/register");
                  break;
                case "entity-master-role":
                  navigate("/entitymaster/deposit");
                  break;
                case "notification-manager":
                  navigate("/cms/websitecontent/internal/3");
                  break;
                case "reviewer-role":
                  navigate("/entitymaster/deposit");
                  break;
                case "user-manager-admin-role":
                  navigate("/usermanagement/role");
                  break;
                case "mis-report-role":
                  navigate("/mis");
                  break;
              }
            });
          }
          // setLoader(false);
        }, 1000);
      })
      .catch((error) => {
        setShowError(error?.response?.data?.message);
        Swal.fire({
          icon: "error",
          text: error?.response?.data?.message,
        });
        setLoader(false);
      });
  };

  const handleFileUpload = (file: File | null) => {
    setFileName(file?.name);
    if (file) {
      setIsFileUploaded(true);

      convertFileToBase64(
        file,
        (hex) => {
          setHexData(hex);
        },
        (base64) => {
          setBase64Data(base64);
        }
      );
    } else {
      setIsFileUploaded(false);
      setBase64Data(""); //    Clear the base64 data if no file is uploaded
      setHexData(""); //    Clear the hex data as well
    }
  };
  const canSubmit = watch("email") && watch("password");
  const passwordValue = watch("password");

  return (
    <>
      {show && (
        <div
          className={`h-screen w-screen flex justify-center items-center ${
            isBlurred ? "filter blur-sm" : ""
          }`}
        >
          <div className="bg-black bg-opacity-30 absolute inset-0 flex justify-center items-center shadow-lg">
            <div className="bg-white p-3 rounded-lg md:w-[946px] w-full grid grid-cols-1 md:grid-cols-2 gap-4 shadow-lg m-4">
              <div className="order-1 md:order-2 mt-3">
                <div className="flex justify-between mt-[40px]">
                  <div className="w-full text-center">
                    <h1 className="text-[24px] font-bold text-black text-gilroy-medium">
                      Login
                    </h1>
                  </div>
                  <div className="lg:top-2 lg:right-10 relative md:top-2 md:right-10 top-[-6rem]"></div>
                </div>
                <form
                  className="relative -top-4 "
                  onSubmit={handleSubmit((data) => {
                    loginApiCall();
                  })}
                >
                  <div className="mt-4 md:mt-[36px] px-4 md:px-[40px]">
                    <div className="">
                      <label
                        htmlFor="Email id / Mobile no."
                        className="text-base font-normal text-gilroy-medium my-3"
                      >
                        Email id / Mobile no
                      </label>
                      <InputFields
                        {...register("email", {
                          required: "This field is required",
                          validate: {
                            eitherEmailOrPhone: (value) =>
                              /^\s*[a-zA-Z0-9._-]+(\+[a-zA-Z0-9._-]+)?@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}\s*$/.test(
                                value
                              ) ||
                              /^\s*\+?[0-9]{10}\s*$/.test(value) ||
                              "Enter a valid email or phone number ",
                          },
                        })}
                        placeholder="Type input"
                        error={error}
                        disabled={dsc}
                      />
                      {errors.email && (
                        <p className="text-red-500">{errors.email.message}</p>
                      )}
                    </div>
                    <div className="mt-4">
                      <label
                        htmlFor="Password"
                        className="text-base font-normal text-gilroy-medium my-3"
                      >
                        Password
                      </label>
                      <InputFieldPassword
                        // {...register("password")}
                        {...register("password", {
                          required: "Password is required",
                          minLength: {
                            value: 8,
                            message: "Password must have at least 8 characters",
                          },
                        })}
                        placeholder="Type here"
                        error={error}
                        disabled={dsc}
                      />
                      {errors.password && (
                        <p className="text-red-500">
                          {errors.password.message}
                        </p>
                      )}
                      <p
                        className="text-xs font-normal text-gilroy-medium text-end text-[#1C468E] cursor-pointer "
                        onClick={NavigateToForgetPassword}
                      >
                        Forgot password?
                      </p>
                    </div>
                    <div className="mt-4 lg:mt-8">
                      {dsc && (
                        <>
                          {isDscKeyAvbl === "false" ? (
                            <Dscbutton
                              onFileUpload={handleFileUpload}
                              disabled={false}
                              fileName={fileName}
                            >
                              Upload Document
                            </Dscbutton>
                          ) : (
                            <DscKeyLogin
                              setDscSelected={setDscSelected}
                              isDscSelected={isDscSelected}
                              setDscCertificate={setDscCertificate}
                            />
                          )}
                        </>
                      )}
                    </div>
                    {/* <div className="mt-2 lg:mt-4  text-center">
                      <span className="text-red-500">{showError}</span>
                    </div> */}
                    <div className="flex justify-center items-center my-14">
                      <Button
                        type="submit"
                        loader={loader}
                        label={!loader ? "Login" : "...."}
                        disabled={!canSubmit || loader}
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div className="md:order-1 hidden md:flex justify-center items-center">
                <img
                  src={LoginPageIcon}
                  alt="LoginPageIcon"
                  className="w-[200px] h-auto md:w-full"
                />
              </div>
              <div className="md:order-1 flex justify-center items-center md:hidden">
                <img
                  src={MobileIcon}
                  alt="MobileIcon"
                  className="w-[200px] h-auto md:w-full"
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {showForgetPasswordModel && (
        <ForgetPasswordModel
          closeForgetPasswordModel={CloseForgetPasswordModel}
          closeForgetPasswordandshowLogin={CloseForgetPasswordandshowLogin}
          closeForgetPasswordAndshowRegisterMail={
            CloseForgetPasswordAndshowRegisterMail
          }
        />
      )}
      {showRegisterMailModel && (
        <RegisterMailModel
          closeRegisterMail={CloseRegisterMail}
          closeRegisterMainlandShowLoginModel={
            CloseRegisterMainlandShowLoginModel
          }
        />
      )}
    </>
  );
};

export default LoginPage;
