import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import TaskTabs from "../../components/MyTasks/TaskTabs";
import ReactTable from "../../components/common/ReactTable";
import { createColumnHelper } from "@tanstack/table-core";
import FolderIcon from "../../assets/images/new_images/FolderOpen.png";
import Button from "../../components/form/Button";
import BackArrow from "../../assets/images/BackArrow.svg";
import ReferModelPopup from "../../components/MyTasks/ReferModelPopup";
import ApprovModelPopup from "../../components/MyTasks/ApproveModelPopup";
import ReturnModelPopup from "../../components/MyTasks/ReturnModelPopup";
import SubRejectModelPopup from "../../components/MyTasks/SubRejectModelPopup";
import "../myTask/mytaskform.css";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosUAMInstance } from "../../utils/axios";
import { number } from "yup";
import LoaderSpin from "../../components/LoaderSpin";
import { useDepositTakerRegistrationStore } from "../../state/registrationStore";
import { getMimeTypeFromArrayBuffer } from "../../utils/commonFunction";
import Swal from "sweetalert2";
import moment from "moment";

const MyTaskForm = () => {
  const location = useLocation();
  const depositTakerId = location.state?.depositTakerId;
  const approverRelation =
    location.state?.approverRelation?.firstName +
    " " +
    location.state?.approverRelation?.lastName;
  const status = location?.state?.status;
  const pages = location?.state?.pages;
  console.log({ pages }, "pages");
  const approvalDocuments = location?.state?.approvalDocuments?.name;
  const approveTimeStamp = location?.state?.approveTimeStamp;
  const [loader, setLoader] = useState<boolean>(false);
  const [loader1, setLoader1] = useState<boolean>(false);

  const [viewLoaders, setViewLoaders] = useState<Record<number, boolean>>({});
  const [dataBranch, setDataBranch] = useState([]);
  const [dataManagementTeam, setDataManagementTeam] = useState([]);
  const { setAllFormData, allFormData, documentData, setAllDocumentData } =
    useDepositTakerRegistrationStore((state) => state);
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  console.log("management---", dataManagementTeam);

  const [place, setPlace] = useState<any>();
  const [date, setDate] = useState<any>();
  console.log({ place });
  const getBranches = () => {
    setLoader(true);
    axiosUAMInstance
      .get(`deposit-taker/branch/${depositTakerId}`)
      .then((res) => {
        console.log(res?.data?.branchCreateDate, "branches place");
        setPlace(res?.data?.branchCreatePlace);
        setDate(res?.data?.branchCreateDate);
        setDataBranch(res?.data?.data?.branches);

        setLoader(false);
      })
      .catch((error) => {
        console.log(error.message);
        setLoader(false);
      });
  };

  useEffect(() => {
    getBranches();
    getManagementDetails();
  }, []);

  const getManagementDetails = () => {
    setLoader(true);
    axiosUAMInstance
      .get(`deposit-taker/management-team/${depositTakerId}`)
      .then((res) => {
        setDataManagementTeam(res?.data?.data);
        console.log(res.data);

        setLoader(false);
      })
      .catch((error) => {
        console.log(error.message);
        setLoader(false);
      });
  };

  const fetchFormFields = () => {
    setLoader1(true);
    axiosUAMInstance
      .get(`/registration/field-data/1?status=addToProfile`)
      .then(async (response) => {
        if (response?.data?.success) {
          let dtData: any = [];
          try {
            let depositTakerData = await axiosUAMInstance.get(
              `/deposit-taker/${depositTakerId}`
            );

            dtData =
              depositTakerData?.data?.data?.depositTaker?.depositTakerFormData;
          } catch (error) {
            console.log("Error");
          }
          console.log({ dtData, response });

          // console.log(dtData, "respnse--------------");
          let modifiedFormFields = response.data.data?.formFields?.map(
            (o: any) => ({
              ...o,
              userInput: dtData
                ? dtData?.find((data: any) => data?.fieldId === o?.id)?.value
                : "",
              error: "",
            })
          );

          let modifiedFileFields =
            response?.data?.data?.registrationDocumentFields?.map((o: any) => ({
              ...o,
              file: dtData
                ? dtData?.find((data: any) => data?.fieldId === o?.id)?.value
                : "",
              error: "",
              fileName: dtData
                ? dtData?.find((data: any) => data?.fieldId === o?.id)?.value
                : "",
              uploadFileId: dtData
                ? dtData?.find((data: any) => data?.fieldId === o?.id)?.value
                : "",
            }));

          let obj = {
            ...response?.data?.data,
            formFields: { form_fields: modifiedFormFields },
          };
          // console.log(obj, "obj-----");
          setAllFormData(obj);
          setAllDocumentData(modifiedFileFields);
        } else {
          throw new Error("Error getting data, Please try later!");
        }
        setLoader(false);
      })
      .catch((error: any) => {
        console.log(error);
        setLoader(false);
      });
  };

  useEffect(() => {
    fetchFormFields();
  }, []);
  const getFileDatafromBuffer = async (arrayBuffer: any) => {
    const buffer = new Uint8Array(arrayBuffer);
    const type = await getMimeTypeFromArrayBuffer(buffer);
    const blob = new Blob([buffer], { type: type ?? "" });
    const imageUrl = URL.createObjectURL(blob);
    window.open(imageUrl, "_blank", "noopener");
  };

  const handleOnClikcView = async (uploadFileId: any, index: number) => {
    try {
      // setViewLoader(true);
      setViewLoaders((prev) => ({ ...prev, [index]: true }));
      const response = await axiosUAMInstance.get(
        `/openkm/get/${uploadFileId}`
      );
      const data = await response.data;
      if (data?.status === "INTERNAL_SERVER_ERROR") {
        Swal.fire({
          icon: "error",
          title: "Internal Server Error",
          text: "Unable to Open File",
        });
        // setViewLoader(false);
        setViewLoaders((prev) => ({ ...prev, [index]: false }));
        return;
      }
      const arrayBuffer = data?.data?.data;

      await getFileDatafromBuffer(arrayBuffer);
      // console.log({buffer, type, blob, url});
      // setViewLoader(false);
      setViewLoaders((prev) => ({ ...prev, [index]: false }));
      // await fetchFormFields();
    } catch (error) {
      console.log({ error });
      // setViewLoader(false);
      setViewLoaders((prev) => ({ ...prev, [index]: false }));
    }
  };

  // const TableType = {
  //   sno: number,
  //   id: number,
  //   depositTakerId: String,
  //   addressLine1: String,
  //   addressLine2: String,
  //   state: String,
  //   district: String,

  // };
  const TableType = {
    sno: number,
    id: number,
    firstName: String,
    middleName: String,
    lastName: String,
    addressLine1: String,
    addressLine2: String,
    pinCode: String,
    pincode: String,
    state: String,
    district: String,
    landlineNumber: String,
    email: String,
    designation: String,
  };
  let count: number;
  // const serialNoGen = (page: number) => {
  //   count = (page - 1) * 10;
  // };
  // serialNoGen(page);
  const serialNoGen = (page: number) => {
    count = (page - 1) * 10;
  };
  serialNoGen(page);

  const columnHelper = createColumnHelper<typeof TableType>();

  // const columns = [
  //   columnHelper.accessor("sno", {
  //     cell: () => {
  //       while (count <= total) {
  //         count++;
  //         return count;
  //       }
  //     },
  //     header: () => <span>Sr. No.</span>,
  //   }),
  //   // columnHelper.accessor("sno", {
  //   //   cell: (info) => info.renderValue(),
  //   //   header: () => <span>Sr. No.</span>,
  //   // }),
  //   columnHelper.accessor("depositTakerId", {
  //     cell: (info) => info.renderValue(),
  //     header: () => <span>Deposit Taker Id</span>,
  //   }),
  //   columnHelper.accessor("addressLine1", {
  //     cell: (info) => info.renderValue(),
  //     header: () => <span>Address Line 1</span>,
  //   }),
  //   columnHelper.accessor("addressLine2", {
  //     cell: (info) => info.renderValue(),
  //     header: () => <span>Address Line 2</span>,
  //   }),
  //   columnHelper.accessor("state", {
  //     cell: (info) => info.renderValue(),
  //     header: () => <span>State</span>,
  //   }),
  //   columnHelper.accessor("district", {
  //     cell: (info) => info.renderValue(),
  //     header: () => <span>District</span>,
  //   }),
  // ];
  const columns = [
    columnHelper.accessor("sno", {
      header: () => <span>Sr. No.</span>,
      cell: (info) => {
        // Calculate serial number based on current page and index of the row
        const serialNumber = (page - 1) * pageSize + (info.row.index + 1);
        return <span>{serialNumber}</span>;
      },
    }),
    columnHelper.accessor("addressLine1", {
      cell: (info) => info.renderValue(),
      header: () => <span>Address Line 1</span>,
    }),
    columnHelper.accessor("addressLine2", {
      cell: (info) => info.renderValue(),
      header: () => <span>Address Line 2</span>,
    }),
    columnHelper.accessor("pinCode", {
      cell: (info) => info.renderValue(),
      header: () => <span>Pin code</span>,
    }),
    columnHelper.accessor("state", {
      cell: (info) => info.renderValue(),
      header: () => <span>State</span>,
    }),
    columnHelper.accessor("district", {
      cell: (info) => info.renderValue(),
      header: () => <span>District</span>,
    }),
  ];
  const columnsMangement = [
    columnHelper.accessor("sno", {
      header: () => <span>Sr. No.</span>,
      cell: (info) => {
        const serialNumber = (page - 1) * pageSize + (info.row.index + 1);
        return <span>{serialNumber}</span>;
      },
    }),
    columnHelper.accessor("firstName", {
      cell: (info) => info.renderValue(),
      header: () => <span>First Name</span>,
    }),
    columnHelper.accessor("middleName", {
      cell: (info) => info.renderValue(),
      header: () => <span>Middle Name</span>,
    }),
    columnHelper.accessor("lastName", {
      cell: (info) => info.renderValue(),
      header: () => <span>Last Name</span>,
    }),
    columnHelper.accessor("addressLine1", {
      cell: (info) => info.renderValue(),
      header: () => <span>Address Line 1</span>,
    }),
    columnHelper.accessor("addressLine2", {
      cell: (info) => info.renderValue(),
      header: () => <span>Address Line 2</span>,
    }),
    columnHelper.accessor("pincode", {
      cell: (info) => info.renderValue(),
      header: () => <span>Pincode</span>,
    }),
    columnHelper.accessor("state", {
      cell: (info) => info.renderValue(),
      header: () => <span>State</span>,
    }),
    columnHelper.accessor("district", {
      cell: (info) => info.renderValue(),
      header: () => <span>District</span>,
    }),
    columnHelper.accessor("landlineNumber", {
      cell: (info) => info.renderValue(),
      header: () => <span>Landline Number</span>,
    }),
    columnHelper.accessor("email", {
      cell: (info) => info.renderValue(),
      header: () => <span>Email</span>,
    }),
    columnHelper.accessor("designation", {
      cell: (info) => info.renderValue(),
      header: () => <span>Designation</span>,
    }),
  ];

  const [referModel, setRefererModel] = useState<boolean>(false);
  const [approveModel, setApproveModel] = useState<boolean>(false);
  const [returnModel, setReturnModel] = useState<boolean>(false);
  const [rejectModel, setRejectModel] = useState<boolean>(false);
  const handleRefer = () => {
    setRefererModel(true);
  };
  const handleReturn = () => {
    setReturnModel(true);
  };
  const handleApproveModel = () => {
    setApproveModel(true);
  };
  const handleRejectModel = () => {
    setRejectModel(true);
  };

  const CloseReferModel = () => {
    setRefererModel(false);
  };
  const CloseApproveModel = () => {
    setApproveModel(false);
  };

  const CloseReturnModel = () => {
    setReturnModel(false);
  };
  const CloseRejectModel = () => {
    setRejectModel(false);
  };
  const navigate = useNavigate();
  const handleStatus = (currentStatus: string) => {
    switch (currentStatus) {
      case "TRANSIT":
        return true;
      case "MOD_TRANSIT":
        return true;
      default:
        return false;
    }
  };
  const handleBack = () => {
    const currentPage = pages || 1;
    navigate("/mytask/deposit", { state: { currentPage } });
  };

  return (
    <div>
      <Layout
        layout={
          <div className="relative mx-4 xl:ml-[40px]">
            <div className="mt-6 ">
              <TaskTabs />
            </div>
            <div>
              <>
                {loader ? (
                  <LoaderSpin />
                ) : (
                  <>
                    <div className="container mx-auto">
                      <div id="reviewContent">
                        {handleStatus(status) && (
                          <p>
                            This record has been approved by {approverRelation}
                            &nbsp;
                            {approveTimeStamp &&
                              `on ${moment(approveTimeStamp).format(
                                "DD/MM/YYYY"
                              )}`}
                          </p>
                        )}

                        <h1 className=" text-gilroy-bold text-[#24222B] text-2xl font-bold  my-7"></h1>
                        {allFormData?.entitySections
                          ?.filter(
                            (s: any) => s?.sectionName !== "Upload Documents"
                          )
                          ?.map((section: any, index: number) => (
                            <div className="mb-[16px] " key={index}>
                              <div className="rounded-t-lg bg-[#e7f0ff] flex justify-between h-[57px] font-bold">
                                <p className="lg:w-[152px] ml-[16px] mt-[16px] text-[16px] lg:text-[20px] pb-2 text-nowrap">
                                  {section?.sectionName === "Nodal Details"
                                    ? "Nodal Officer Details"
                                    : section?.sectionName}
                                </p>
                                <button className="text-[#385723] text-[16px] lg:text-[20px] mr-[13px] font-normal ">
                                  {/* {section.buttonText} */}
                                </button>
                              </div>
                              <div className="shadow-sm p-5 rounded-md">
                                <div className="flex flex-col justify-between w-full sm:flex-row gap-y-[16px]">
                                  <div className="w-full grid grid-cols-2">
                                    {allFormData?.formFields?.form_fields?.sort((a : any, b : any) => a.sortOrder - b.sortOrder)
                                      ?.filter(
                                        (f: any) => f?.sectionId === section?.id
                                      )
                                      ?.map((field: any, idx: number) => (
                                        <div
                                          className={`${
                                            idx % 2 === 0
                                              ? "pr-4 pt-2 sm:border-r-[0.5px] border-r-[#385723] border-opacity-20"
                                              : "pl-4 pt-2"
                                          } flex justify-between`}
                                          key={idx}
                                        >
                                          <div className="opacity-60">
                                            {field.label}
                                            {/* <span className="text-[#ff0000]">
                                              *
                                            </span> */}
                                          </div>
                                          {/* <div className="break-all">
                                            {field.label === "DSC3 Certificate"
                                              ? "DSC Certification "
                                              : field?.label ===
                                                "Regulator approval Date"
                                              ? moment(field.userInput).format(
                                                  "DD/MM/YYYY"
                                                )
                                              : field.userInput}
                                          </div> */}
                                          <div className="break-all">
                                            {field.label === "DSC3 Certificate"
                                              ? "DSC Certification "
                                              : field?.label ===
                                                  "Regulator approval Date" ||
                                                field?.label ===
                                                  "Date of In-corporation"
                                              ? moment(field.userInput).format(
                                                  "DD/MM/YYYY"
                                                )
                                              : field.userInput}
                                          </div>
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                      <div className="w-full overflow-x-auto ">
                        <div className="rounded-t-lg bg-[#e7f0ff] flex justify-between h-[57px] font-bold mb-4">
                          <p className="lg:w-[152px] ml-[16px] mt-[16px] text-[16px] lg:text-[20px] pb-2 text-nowrap">
                            Branches
                          </p>
                        </div>
                        <div
                          className="custom-scrollbar"
                          style={{ maxHeight: "200px", overflowY: "auto" }}
                        >
                          {loader ? (
                            <LoaderSpin />
                          ) : dataBranch.length > 0 ? (
                            <ReactTable
                              defaultData={dataBranch}
                              columns={columns}
                              lineHeight={true}
                            />
                          ) : (
                            <div className=" flex justify-center items-center">
                              <p>No data available</p>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="w-full overflow-x-auto mt-4 mb-3">
                        <div className="rounded-t-lg bg-[#e7f0ff] flex justify-between h-[57px] font-bold mb-4">
                          <p className="lg:w-[152px] ml-[16px] mt-[16px] text-[16px] lg:text-[20px] pb-2 text-nowrap">
                            Management Details
                          </p>
                        </div>
                        <div
                          className="custom-scrollbar"
                          style={{ maxHeight: "200px", overflowY: "auto" }}
                        >
                          {loader ? (
                            <LoaderSpin />
                          ) : dataManagementTeam.length > 0 ? (
                            <ReactTable
                              defaultData={dataManagementTeam}
                              columns={columnsMangement}
                              lineHeight={true}
                            />
                          ) : (
                            <div className=" flex justify-center items-center">
                              <p>No data available</p>
                            </div>
                          )}
                        </div>
                      </div>

                      <div>
                        <div className="rounded-t-lg bg-[#e7f0ff] flex justify-between h-[57px] font-bold mb-4">
                          <p className="lg:w-[152px] ml-[16px] mt-[16px] text-xl lg:text-[20px] pb-2 text-nowrap">
                            Upload Documents
                          </p>
                        </div>
                        {documentData.map((uploadItem: any, index: number) => {
                          return (
                            <>
                              <div
                                key={index}
                                className="rounded-t-lg rounded-b-lg bg-[#e7f0ff] flex justify-between items-center h-16 text-gilroy-bold mb-4"
                              >
                                <div className="flex p-7 space-x-2 justify-center items-center">
                                  <div className="">
                                    <img
                                      src={FolderIcon}
                                      alt={FolderIcon}
                                      className="w-12"
                                    />
                                  </div>
                                  <div className="">
                                    <h1 className="text-sm font-normal text-gilroy-medium text-[#1D1D1B]">
                                      {uploadItem?.documentName}
                                    </h1>
                                    {/* <p className="text-base font-normal text-gilroy-medium text-gray-400">
                                      Document.pdf
                                    </p> */}
                                  </div>
                                </div>
                                <div className="mr-3">
                                  <Button
                                    loader={viewLoaders[index]}
                                    disabled={
                                      uploadItem.uploadFileId === undefined &&
                                      uploadItem.file === undefined
                                    }
                                    label="View"
                                    type="button"
                                    width="100px"
                                    onClick={() =>
                                      handleOnClikcView(
                                        uploadItem?.uploadFileId,
                                        index
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </>
                )}

                <div className="flex  justify-between items-center flex-wrap">
                  <div>
                    <span className="text-sm font-normal text-gilroy-medium ">
                      Date :{" "}
                      {date && ` ${moment(date).format("DD-MM-YYYY HH:mm")}`}
                    </span>
                  </div>
                  <div>
                    <span className="text-sm font-normal text-gilroy-medium ">
                      Place : {place}
                    </span>
                  </div>
                </div>

                <div className="my-11 flex flex-col sm:flex-row justify-between items-center">
                  <div
                    className="flex items-center cursor-pointer space-x-2 mb-3 sm:mb-0"
                    // onClick={() => navigate(-1)}
                    onClick={handleBack}
                  >
                    <img src={BackArrow} alt={BackArrow} />
                    <p className="text-sm font-normal text-gilroy-regular">
                      Back
                    </p>
                  </div>
                  <div className="flex flex-wrap justify-center sm:w-7/12 items-center space-x-3 sm:mb-3">
                    <div className="mb-3 sm:mb-0">
                      <Button
                        type="button"
                        label="Return"
                        width="100px"
                        textColor="#F5BD0B"
                        borderColor="#F5BD0B"
                        backgroundColor="white"
                        onClick={handleReturn}
                      />
                    </div>
                    <div className="mb-3 sm:mb-0">
                      <Button
                        type="button"
                        label="Reject"
                        width="100px"
                        textColor="#E63312"
                        borderColor="#E63312"
                        backgroundColor="white"
                        onClick={handleRejectModel}
                      />
                    </div>
                    <div className="mb-3 sm:mb-0">
                      <Button
                        type="button"
                        label="Refer to regulator"
                        width="150px"
                        textColor="#1c468e"
                        borderColor="#1c468e"
                        backgroundColor="white"
                        onClick={handleRefer}
                      />
                    </div>
                    <div className="mb-3 sm:mb-0">
                      <Button
                        type="button"
                        label="Approve"
                        width="100px"
                        onClick={handleApproveModel}
                      />
                    </div>
                  </div>
                </div>

                {returnModel && (
                  <ReturnModelPopup
                    onClose={CloseReturnModel}
                    onSave={() => {}}
                    entityType="DT"
                  />
                )}
                {referModel && (
                  <ReferModelPopup
                    onClose={CloseReferModel}
                    onSave={() => {}}
                  />
                )}
                {approveModel && (
                  <ApprovModelPopup
                    onClose={CloseApproveModel}
                    onSave={() => {}}
                  />
                )}
                {rejectModel && (
                  <SubRejectModelPopup
                    onClose={CloseRejectModel}
                    onSave={() => {}}
                    entityType="DT"
                  />
                )}
              </>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default MyTaskForm;
