import React, { useState, useEffect, useCallback } from "react";
import Layout from "../../components/layout/Layout";
import CmsTabs from "../../components/cms/CmsTabs";
import { createColumnHelper } from "@tanstack/table-core";
import ReactTable from "../../components/common/ReactTable";
import CustomPagination from "../../components/cms/Pagination/CustumPagination";
import SubTabs from "../../components/cms/SubTabs";
import InputFields from "../../components/form/InputField";
import Add from "../../assets/images/new_images/add-circle.png";
import DirectBox from "../../assets/images/send.png";
import UploadDocumentModal from "../../components/cms/UploadDocumentModal";
import AdditionSuccessfulModalOne from "../../components/cms/AdditonSuccessfulModalOne";
import { useNavigate } from "react-router";
import { axiosUAMInstance } from "../../utils/axios";
import LoaderSpin from "../../components/LoaderSpin";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { getMimeTypeFromArrayBuffer } from "../../utils/commonFunction";
import Swal from "sweetalert2";

type TableType = {
  sno: number;
  id: number;
  name: string;
  attachment: string;
  date: string;
  uploadby: string;
  user: {
    name: string;
  };
  regulator: {
    name: string;
  };
};

const columnHelper = createColumnHelper<TableType>();
const getFileDatafromBuffer = async (arrayBuffer: any) => {
  const buffer = new Uint8Array(arrayBuffer);
  const type = await getMimeTypeFromArrayBuffer(buffer);
  const blob = new Blob([buffer], { type: type ?? "" });
  const imageUrl = URL.createObjectURL(blob);
  window.open(imageUrl, "_blank", "noopener");
};

const handleOnClikcView = async (uploadFileId: any) => {
  try {
    // setLoader(true);
    const response = await axiosUAMInstance.get(`/openkm/get/${uploadFileId}`);
    const data = await response.data;
    if (data?.status === "INTERNAL_SERVER_ERROR") {
      Swal.fire({
        icon: "error",
        title: "Internal Server Error",
        text: data.message || "Unable to Open File",
      });
      return;
    }
    const arrayBuffer = data?.data?.data;

    await getFileDatafromBuffer(arrayBuffer);
  } catch (error) {
    console.log({ error });
  }
};

const CmsMasterApprovedDocument = () => {
  const [showDocumentPopup, setShowDocumentPopup] = useState<boolean>(false);
  const [showSucessPopup, setShowSucessPopup] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [total, setTotal] = useState(0);

  const [approveData, setApproveData] = useState([]);
  const [searchText, setSearchText] = useState<string>("");
  const [isDocumentAdded, setDocumentAdded] = useState<boolean>(false);

  let count: number;
  const serialNoGen = (page: number) => {
    count = (page - 1) * 10;
  };
  serialNoGen(page);

  const columns = [
    columnHelper.accessor("sno", {
      cell: () => {
        while (count <= total) {
          count++;
          return count;
        }
      },
      header: () => <span>S.No</span>,
    }),
    columnHelper.accessor("name", {
      cell: (info) => info.getValue(),
      header: () => <span>Name</span>,
    }),
    columnHelper.accessor("attachment", {
      cell: (info) => (
        <div
          className="flex items-center justify-center cursor-pointer"
          onClick={() => handleOnClikcView(info.row.original.attachment)}
        >
          {info.getValue()}
          <img src={DirectBox} alt="Attachment" className="ml-1 w-5 h-5" />
        </div>
      ),
      header: () => <span>Attachment</span>,
    }),
    columnHelper.accessor("user.name", {
      cell: (info) => info.getValue(),
      header: () => <span>Uploaded By</span>,
    }),
    columnHelper.accessor("regulator.name", {
      cell: (info) => info.getValue(),
      header: () => <span>Regulator</span>,
    }),
  ];

  const navigate = useNavigate();
  const handleDocumentPopup = () => {
    setShowDocumentPopup(true);
  };
  const handleCloseDocumentPopup = () => {
    setShowDocumentPopup(false);
  };
  const handleSucessPopup = () => {
    setShowSucessPopup(false);
  };

  const handleSave = () => {
    setShowSucessPopup(true);
  };

  // const apiCall = () => {
  //   setLoader(true);
  //   axiosUAMInstance
  //     .get("/approval-documents", {
  //       params: {
  //         page: page,
  //         pageSize: pageSize,
  //         search: searchText,
  //       },
  //     })
  //     .then((res) => {
  //       if (res.status === 200) {
  //         setApproveData(res?.data?.data?.documents);
  //         setTotal(res?.data?.data?.totalData);
  //         // console.log(res?.data?.data?.totalData, "res");
  //       }
  //       setLoader(false);
  //     })
  //     .catch((error) => {
  //       console.log(error.message);
  //       setLoader(false);
  //     });
  // };
  const apiCall = useCallback(() => {
    setLoader(true);
    axiosUAMInstance
      .get("/approval-documents", {
        params: { page, pageSize, search: searchText },
      })
      .then((res) => {
        if (res.status === 200) {
          setApproveData(res?.data?.data?.documents);
          setTotal(res?.data?.data?.totalData);
        }
        setLoader(false);
      })
      .catch((error) => {
        console.log(error.message);
        setLoader(false);
      });
  }, [page, pageSize, searchText]);

  // useEffect(() => {
  //   apiCall();
  // }, [pageSize, page, searchText, showSucessPopup]);
  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      apiCall();
    }, 400);

    return () => clearTimeout(delayDebounce);
  }, [apiCall]);
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formatter = capitalizeFirstLetter(event.target.value);
    setSearchText(event.target.value);
  };
  useEffect(() => {
    const delaySearch = setTimeout(() => {
      apiCall();
    }, 400);

    return () => clearTimeout(delaySearch);
  }, [page, pageSize, searchText, isDocumentAdded]);

  return (
    <Layout
      layout={
        <div
          className="md:px-8 px-1 pb-[44px] relative"
          style={{ minHeight: "calc(100vh - 110px)" }}
        >
          <div className="mt-4">
            <CmsTabs />
          </div>
          <div className="my-6">
            <SubTabs />
          </div>
          <div className="flex justify-between items-center flex-wrap">
            <div>
              <div className="mb-2">
                <label
                  htmlFor="Country Search"
                  className="text-base font-normal text-gilroy-medium"
                >
                  Documents Search
                </label>
              </div>
              <div className="md:w-[480px] xl:w-[820px] 2xl:w-[800px] w-[300px]">
                <InputFields
                  height="40px"
                  // width="600px"
                  padding="10px"
                  placeholder="Search by Unique ID/name"
                  value={searchText}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="flex items-center mt-5  ">
              <div className="mt-2">
                <button
                  className="p-3 text-[#1c468e] h-10 rounded-lg border border-[#1c468e] text-base text-gilroy-medium flex  items-center justify-center"
                  onClick={handleDocumentPopup}
                >
                  <img src={Add} alt="Add" className="mr-2" />
                  Add New Document
                </button>
              </div>
            </div>
          </div>
          <div className="mt-4 mb-20">
            {loader ? (
              <LoaderSpin />
            ) : approveData.length > 0 ? (
              <ReactTable
                defaultData={approveData}
                columns={columns}
                borderB={false}
              />
            ) : (
              <div className=" flex justify-center items-center">
                <h1>No data available</h1>
              </div>
            )}
          </div>
          <div className="mt-10 absolute bottom-0">
            {approveData && approveData?.length > 0 && (
              <CustomPagination
                bottomLine={true}
                currentPage={page}
                setCurrentPage={setPage}
                totalItems={total}
                itemsPerPage={10}
                maxPageNumbersToShow={5}
              />
            )}
          </div>

          {showDocumentPopup && (
            <UploadDocumentModal
              onClose={handleCloseDocumentPopup}
              onSave={handleSave}
              setDocumentAdded={setDocumentAdded}
              isDocumentAdded={isDocumentAdded}
            />
          )}
          {showSucessPopup && (
            <AdditionSuccessfulModalOne
              heading="Document upload Successful"
              paragraph="You have successfully uploaded abc.pdf  "
              onClose={handleSucessPopup}
              onSave={handleSucessPopup}
            />
          )}
        </div>
      }
    />
  );
};

export default CmsMasterApprovedDocument;
