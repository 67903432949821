import { FC, useState, useEffect } from "react";
import add from "../../assets/images/add.svg";
import InputFields from "../common/InputField";
import SelectButton from "./SelectButton";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useForm, SubmitHandler } from "react-hook-form";
import LoaderSpin from "../LoaderSpin";
import "./EditModel.css";
import useCmsFormControlStore from "../../state/cmsFormControl";
import AddFormFieldValidationModalschema from "../../yupValidationSchema/AddFormFieldSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import Swal from "sweetalert2";
import { axiosUAMInstance } from "../../utils/axios";
import CreatedBy from "../../pages/SchemeMaster/CreatedBy";

interface Option {
  value: string;
  label: string;
  id: number;
}

interface FileType {
  id: number;
  name: string;
  createdAt: string;
  updatedAt: string;
}

interface EntitySection {
  id: number;
  entityId: number;
  sectionName: string;
  createdAt: string;
  updatedAt: string;
}

interface FormData {
  fieldName: string;
  placeholder: string;
  typeId: number;
  sectionId: number;
}

interface Props {
  handleOpenClose: () => void;
  heading: string;
  entitySectionsData?: any[];
  validations?: any[];
  fieldTypes?: any[];
  type: string;
  editData?: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 4,
};

const AddFormFieldModalV2: FC<Props> = ({
  handleOpenClose,
  heading,
  entitySectionsData,
  validations,
  fieldTypes,
  type,
  editData,
}: Props) => {
  const [selectDropdownOptions, setSelectDropdownOptions] = useState<any[]>([]);
  const updatedFormField = useCmsFormControlStore(
    (state) => state.updatedFormField
  );
  const [selectedFieldType, setSelectedFieldType] = useState<Option | null>(
    null
  );
  const [selectedSection, setSelectedSection] = useState<Option | null>(null);
  const [selectedValidations, setSelectedValidations] = useState<any[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [apiError, setApiError] = useState<string>("");
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);
  const [dropdownValues, setDropdownValues] = useState<any[]>([]);
  const [selectedDropdown, setSelectedDropdownOption] = useState<Option | null>(
    null
  );
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    setError,
    watch,
    clearErrors,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(AddFormFieldValidationModalschema),
  });

  useEffect(() => {
    dropDownAPICall();
    if (editData !== undefined) {
      setValue("fieldName", editData?.label);
      setValue("placeholder", editData?.placeholder);
      setValue("sectionId", editData?.sectionId);
      setValue("typeId", editData?.typeId);

      // Set Field
      setSelectedFieldType({
        label: fieldTypes?.find((f) => f.id === editData?.typeId)?.name,
        id: editData?.id,
        value: editData?.label,
      });

      // Set Section
      setSelectedSection({
        label: entitySectionsData?.find((f) => f.id === editData?.sectionId)
          ?.sectionName,
        id: editData?.sectionId,
        value: entitySectionsData?.find((f) => f.id === editData?.sectionId)
          ?.sectionName,
      });

      // Set Validations
      setSelectedValidations(
        editData?.regFormFieldsValidations?.map((v: any) => ({
          ...v,
          label: validations?.find((o) => o?.id === v?.validationId)
            ?.vld_type_name,
          input_required: validations?.find((o) => o?.id === v?.validationId)
            ?.input_required,
        }))
      );

      // Set Drop Down if field type is select
      if (
        fieldTypes?.find((f) => f.id === editData?.typeId)?.name === "select"
      ) {
        setSelectedDropdownOption({
          id: editData?.dropdown_options?.id,
          label: editData?.dropdown_options?.name,
          value: editData?.dropdown_options?.name,
        });
      }
    }
  }, [editData]);

  const options: Option[] = fieldTypes
    ? fieldTypes.map((fileType) => ({
        value: fileType.name,
        label: fileType.name,
        id: fileType.id,
      }))
    : [];

  const options1 =
    entitySectionsData?.map((section) => ({
      value: section.sectionName,
      label: section.sectionName,
      id: section.id,
    })) || [];

  const customValidations =
    validations?.map((section) => ({
      value: section.vld_type_name,
      label: section.vld_type_name,
      id: section.id,
      ...section,
    })) || [];

  useEffect(() => {
    setIsFormSubmitted(false);
  }, [selectedFieldType, selectedSection]);

  const handleFieldType = (option: Option) => {
    setSelectedDropdownOption(null);
    if (option.label === "select") {
      setDropdownValues([{ name: "" }]);
    }
    setSelectedFieldType(option);
    setValue("typeId", option.id);
    clearErrors("typeId");
  };

  const handleSectionChange = (option: Option) => {
    setSelectedSection(option);
    setValue("sectionId", option.id);
    clearErrors("sectionId");
  };

  const handleDropdownSelect = (data: any) => {
    setSelectedDropdownOption(data);
  };

  const handleValidationsSelect = (data: any) => {
    if (!selectedValidations.some((v) => v.validationId === data.id)) {
      setSelectedValidations((v) => [
        ...v,
        {
          validationId: data?.id,
          patternValue: "",
          label: data.label,
          input_required: data?.input_required,
        },
      ]);
    }
  };

  const handleRemoveValidation = (id: number) => {
    if (
      selectedValidations.length > 0 &&
      selectedValidations.some((v) => v.validationId === id)
    ) {
      const filteredArray = selectedValidations.filter(
        (o) => o.validationId !== id
      );
      setSelectedValidations(filteredArray);
    }
  };

  const handleValidationValueChange = (event: any, id: number) => {
    const { value } = event.target;
    setSelectedValidations((v) => {
      return v.map((o) => {
        if (o.validationId === id) {
          return {
            ...o,
            patternValue: value,
            error: value === "" ? "Value should not be empty" : "",
          };
        } else {
          return o;
        }
      });
    });
  };

  const dropDownAPICall = () => {
    axiosUAMInstance
      .get(`/registration/dropdown-components`)
      .then((res: any) => {
        if (res?.data?.success) {
          setSelectDropdownOptions(
            res?.data?.data?.map((o: any) => ({
              value: o.name,
              label: o.name,
              id: o.id,
            }))
          );
        }
      });
  };

  const handleFormSubmit: SubmitHandler<FormData> = (data) => {
    setLoader(true);
    // Validate Validations
    const isError = selectedValidations.some(
      (v) => v?.input_required && v.patternValue?.trim() === ""
    );

    if (isError) {
      let errors = selectedValidations?.map((v) => {
        if (v?.input_required && v.patternValue?.trim() === "") {
          return {
            ...v,
            error: "Value should not be empty",
          };
        }
        return v;
      });
      setSelectedValidations(errors);
      setLoader(false);
      return;
    }

    const requestPayload = {
      label: data.fieldName,
      placeholder: data.placeholder,
      required: false,
      typeId: data.typeId,
      sectionId: data.sectionId,
      validations: selectedValidations?.map((v) => ({
        validationId: v?.validationId,
        patternValue: v?.patternValue,
      })),
      addToRegistration: editData
        ? editData?.addToRegistration
        : type === "registration",
      addToProfile: editData ? editData?.addToProfile : type === "signup",
      dropdown_Components:
        selectedFieldType?.label === "select"
          ? selectedDropdown?.id
          : undefined,

      creationBy: "RGCA",
    };

    axiosUAMInstance[editData ? "patch" : "post"](
      editData
        ? `/registration/edit-form-field/${editData?.id}`
        : `/registration/add-form-field`,
      requestPayload
    )
      .then((response: any) => {
        if (response.data.success) {
          Swal.fire({
            icon: "success",
            title: editData ? "Successfully Updated" : "Successfully Added",
          });
          updatedFormField();
          reset();
          setLoader(false);
          handleOpenClose();
          setLoader(false);
        } else {
          alert(response.data?.message);
        }
      })
      .catch((error: any) => {
        setApiError(error.message);
        setLoader(false);
        setLoader(false);
      });
  };

  const handleChangeOptionData = (event: any, i: number) => {
    const { value } = event.target;
    setDropdownValues((v) => {
      return v.map((o, idx) => {
        if (idx === i) {
          return { name: value };
        } else {
          return o;
        }
      });
    });
  };

  const handleRemoveOption = (i: number) => {
    const filtered = dropdownValues.filter((option, idx) => i !== idx);
    setDropdownValues(filtered);
  };

  const handleAddOption = () => {
    let options: any = [...dropdownValues, { name: "" }];
    setDropdownValues(options);
  };

  return (
    <>
      <Modal
        open={true}
        onClose={handleOpenClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="md:h-auto rounded-2xl p-[8px] text-gilroy-medium shadow-xl bg-white w-[10rem] md:w-[10rem] lg:w-[40rem] ">
            <div
              className="flex flex-row justify-end cursor-pointer"
              onClick={handleOpenClose}
            >
              <img src={add} className="w-6 h-6" alt="icon" />
            </div>
            <h1 className="mt-[12px] text-center text-black text-[24px] font-normal">
              {heading}
            </h1>
            <p className="text-center text-red-500">{apiError}</p>
            <form onSubmit={handleSubmit(handleFormSubmit)} className="p-4 md">
              <div
                className="custom-scrollbar p-4"
                style={{
                  maxHeight: "450px",
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                <div className="my-4">
                  <label
                    htmlFor="fieldName"
                    className=" text-black text-[16px] font-normal  mb-[8px] text-gilroy-medium"
                  >
                    Field Name:
                  </label>
                  <InputFields
                    type="text"
                    id="fieldName"
                    placeholder="type here"
                    {...register("fieldName", {
                      required: "Field Name is required",
                    })}
                    className="shadow appearance-none border border-#E6E6E6 rounded-2xl h-[61px] mb-[24px] mt-[8px] w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                  <p className="text-red-500 -mt-5">
                    {errors?.fieldName?.message}
                  </p>
                </div>
                <div className="my-4">
                  <label
                    htmlFor="fieldName"
                    className=" text-black text-[16px] font-normal  mb-[8px] text-gilroy-medium"
                  >
                    Placeholder:
                  </label>
                  <InputFields
                    type="text"
                    id="placeholder"
                    placeholder="type here"
                    {...register("placeholder", {
                      required: "Placeholder is required",
                    })}
                    className="shadow appearance-none border border-#E6E6E6 rounded-2xl h-[61px] mb-[24px] mt-[8px] w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                  <p className="text-red-500 -mt-5">
                    {errors?.placeholder?.message}
                  </p>
                </div>
                <div className="my-4">
                  <label
                    htmlFor="fileTypes"
                    className=" text-black text-base font-normal mb-[8px] text-gilroy-medium"
                  >
                    Field Type:
                  </label>

                  <SelectButton
                    setOption={handleFieldType}
                    options={options}
                    selectedOption={selectedFieldType}
                    placeholder="Select"
                  />
                  {<p className="text-red-500">{errors.typeId?.message}</p>}
                </div>
                {selectedFieldType?.label === "select" && (
                  <div className="my-4">
                    <label
                      htmlFor="fileTypes"
                      className=" text-black text-base font-normal mb-[8px] text-gilroy-medium"
                    >
                      Select Dropdown Options :
                    </label>

                    <SelectButton
                      setOption={handleDropdownSelect}
                      options={selectDropdownOptions}
                      selectedOption={selectedDropdown}
                      placeholder="Select Dropdown Options  "
                    />
                  </div>
                )}
                {selectedFieldType?.label === "890" && (
                  <div className="my-4">
                    <span className="font-semibold flex justify-between align-middle items-center">
                      Options :{" "}
                      <div
                        onClick={handleAddOption}
                        className="text-green-600 py-2 cursor-pointer hover:text-white hover:bg-green-300 px-4 border rounded-2xl border-green-400 text-lg"
                      >
                        +
                      </div>
                    </span>
                    <div className="mt-4 space-y-2">
                      {dropdownValues.map((d, i) => {
                        return (
                          <div className="flex flex-row items-center justify-between">
                            <InputFields
                              variant="rawInput"
                              value={d?.name}
                              onChange={(e) => handleChangeOptionData(e, i)}
                            />
                            <div
                              onClick={() => handleRemoveOption(i)}
                              className="text-green-600 py-2 ml-10 cursor-pointer hover:text-white hover:bg-red-300 px-4 border rounded-2xl border-red-400 text-lg"
                            >
                              -
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                <div className="my-4">
                  <label
                    htmlFor="fileTypes"
                    className=" text-black text-base font-normal mb-[8px] text-gilroy-medium"
                  >
                    Section:
                  </label>

                  <SelectButton
                    setOption={handleSectionChange}
                    options={options1}
                    selectedOption={selectedSection}
                    placeholder="Select"
                  />
                  {<p className="text-red-500">{errors.sectionId?.message}</p>}
                </div>
                <div className="my-4">
                  <label
                    htmlFor="fileTypes"
                    className=" text-black text-base font-normal mb-[8px] text-gilroy-medium"
                  >
                    Validations :
                  </label>

                  <SelectButton
                    setOption={handleValidationsSelect}
                    options={customValidations}
                    // selectedOption={selectedValidations}
                    multiSelectedOptions={selectedValidations}
                    placeholder="Select"
                  />
                </div>

                {selectedValidations?.length > 0 && (
                  <table className="border-collapse border border-slate-500 w-full hover:border-collapse ">
                    <thead>
                      <tr>
                        <th className="border border-slate-600 p-2">
                          Validation
                        </th>
                        <th className="border border-slate-600 p-2">
                          Value/Pattern
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedValidations?.map((v) => {
                        return (
                          <>
                            <tr>
                              <td className="border border-slate-700 p-7">
                                {v?.label}
                              </td>
                              <td className="border border-slate-700 p-7 relative">
                                {v?.input_required ? (
                                  <>
                                    <InputFields
                                      onChange={(e) =>
                                        handleValidationValueChange(
                                          e,
                                          v?.validationId
                                        )
                                      }
                                      variant="rawInput"
                                      value={v.patternValue}
                                    />
                                    <span className="text-red-500 text-sm absolute right-14">
                                      {v?.error}
                                    </span>
                                  </>
                                ) : (
                                  <span className="text-center">Added</span>
                                )}
                              </td>
                              <td
                                onClick={() =>
                                  handleRemoveValidation(v?.validationId)
                                }
                                className="border border-slate-700 p-7 text-red-500 font-semibold hover:text-white hover:bg-red-400 hover:cursor-pointer text-center"
                              >
                                X
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </div>

              <div>
                <hr className="w-full "></hr>
                <div className="flex flex-row justify-around my-4">
                  <button
                    onClick={handleOpenClose}
                    className="w-[40%] md:w-[224px] h-[56px] rounded-xl border border-[#1c468e] text-[#1c468e]"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={loader || isFormSubmitted}
                    className={`ml-6 md:ml-[32px] w-[40%] md:w-[224px] h-[56px] bg-[#1c468e] rounded-xl text-white ${
                      loader || isFormSubmitted
                        ? "opacity-50 cursor-not-allowed"
                        : ""
                    }`}
                  >
                    {loader ? <LoaderSpin /> : "Save"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default AddFormFieldModalV2;
