import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import ArrowIcon from "../../assets/images/BackArrow.svg";
import CmsTabs from "../../components/cms/CmsTabs";
import SubTabs from "../../components/cms/SubTabs";
import InputFields from "../../components/form/InputField";
import SelectButton from "../../components/form/SelectButton";
import Button from "../../components/form/Button";
import TextArea from "../../components/common/TextArea";
import AdditionSuccessfulModalOne from "../../components/cms/AdditonSuccessfulModalOne";
import { useNavigate } from "react-router";
import { yupResolver } from "@hookform/resolvers/yup";
import AuthorityValidationSchema from "../../yupValidationSchema/CMSAuthorityValidationSchema";
import { useForm } from "react-hook-form";
import { axiosUAMInstance } from "../../utils/axios";
import useFetchEntities from "../../hooks/useFetchEntities";
import useMasterEntitiesStore from "../../state/masterEntitiesStates";
import { fetchStateAndDistrict } from "../../utils/helperFunctions";
import failedLogo from "../../assets/images/FailedIcon.svg";
import useCommonStore from "../../state/commonStore";
const CmsMasterCAuthorityForm = () => {
  const { toggleModal } = useCommonStore((state) => state);
  const handleFormSubmitStateChange = useMasterEntitiesStore(
    (state) => state.handleFormSubmit
  );
  const [selectedJuridiction, setSelectedJuridiction] = useState<string | null>(
    null
  );
  const [successData, setSuccessData] = useState<{
    heading: string;
    paragraph: string;
    logo: any;
  }>({ heading: "", paragraph: "", logo: undefined });
  const [showSuccessPopup, setShowSuccessPopup] = useState<boolean>(false);
  const [states, setStates] = useState([]);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const { entities } = useFetchEntities();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(AuthorityValidationSchema),
  });

  const pincode = watch("pincode");

  useEffect(() => {
    if (/^[0-9]{6}$/.test(pincode)) {
      (async () => {
        const { districtValue, stateValue, error } =
          await fetchStateAndDistrict(pincode);
        if (error) {
          setError("pincode", { message: "Invalid Pincode" });
          return;
        }
        setValue("districtId", districtValue ?? "");
        setValue("stateId", stateValue ?? "");
        setValue("pincode", pincode);
        clearErrors("stateId");
        clearErrors("districtId");
        clearErrors("pincode");
      })();
    }else {
      // If pincode is not exactly 6 digits, clear district and state fields
      setValue("districtId", "");
      setValue("stateId", "");
    }
  }, [pincode]);

  const handleStateChange = (e: any) => {
    const { value } = e?.target;
    setValue("stateId", value);
    setValue("pincode", "");
    if (value?.trim() === "") {
      setError("stateId", { message: "State is required" });
    } else {
      clearErrors("stateId");
    }
  };

  const handleDistrictChange = (e: any) => {
    const { value } = e?.target;
    setValue("districtId", value);
    setValue("pincode", "");
    if (value?.trim() === "") {
      setError("districtId", { message: "District is required" });
    } else {
      clearErrors("districtId");
    }
  };

  const handleJuridictionSelect = (value: any) => {
    setSelectedJuridiction(value.label);
    setValue("jurisdictionId", value.value);
    clearErrors("jurisdictionId");
  };

  const handleFormSubmit = (data: any) => {
    let entityId = null;
    let entity: any = entities?.find((d: any) => d.entityCode === "CA");
    entityId = entity?.id;
    const obj = {
      ...data,
      entityTypeid: entityId,
    };
    setLoader(true);

    axiosUAMInstance
      .post("/master-entities", obj)
      .then((response: any) => {
        if (response.data.success) {
          setSuccessData({
            heading: "Addition Successful",
            paragraph:
              "Competent Authority Details have been successfully added.",
            logo: undefined,
          });
          reset();
          handleFormSubmitStateChange(true);
          setShowSuccessPopup(true);
          setLoader(false);
          toggleModal(true);
          return;
        } else if (!response.data.success) {
          setSuccessData({
            heading: "Addition Failed",
            paragraph: response.data?.data[0]?.message,
            logo: failedLogo,
          });
          setShowSuccessPopup(true);
          toggleModal(true);
        }
        setLoader(false);
      })
      .catch((e) => {
        alert(e);
        setLoader(false);
      });
  };

  const handleClosePopup = () => {
    setShowSuccessPopup(false);
    toggleModal(false);
  };
  const handleSucessSavePopup = () => {
    navigate("/cms/master/authority");
    toggleModal(false);
  };

  // ---------- Fetch States ------------------
  const fetchStates = () => {
    axiosUAMInstance
      .get(`/location/state/95/list?pagesize=50`)
      .then((res) => {
        if (res.data.status === "success") {
          setStates(
            res.data.data.map((state: { id: number; name: string }) => ({
              label: state.name,
              value: state.id,
            }))
          );
        }
      })
      .catch((e) => alert("Error fetching States"));
  };
  //  ________________________________________________ use effects ____________________________________________________________
  useEffect(() => {
    fetchStates();
  }, []);
  return (
    <Layout
      layout={
        <div className="md:px-8 px-1 pb-[44px]">
          <div className="mt-4">
            <CmsTabs />
          </div>
          <div className="my-6">
            <SubTabs />
          </div>
          <div>
            <form
              className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4"
              onSubmit={handleSubmit(handleFormSubmit)}
            >
              <div className="w-full md:w-[360px] lg:w-[200px] xl:w-[250px] 2xl:w-[320px]">
                <label
                  htmlFor="Name of Competent Authority"
                  className="text-base font-normal text-gilroy-medium"
                >
                  Name of Competent Authority
                  <span className="text-red-500 ">*</span>
                </label>
                <div className="mt-2">
                  <InputFields placeholder="Type here" {...register("name")} />
                  <span className="text-red-600">{errors.name?.message}</span>
                </div>
              </div>
              <div className="w-full md:w-[360px] lg:w-[200px] xl:w-[250px] 2xl:w-[320px]">
                <label
                  htmlFor="addressLine1"
                  className="text-base font-normal text-gilroy-medium"
                >
                  Address Line 1 <span className="text-red-500 -ml-1">*</span>
                </label>
                <div className="mt-2">
                  <TextArea
                    placeholder="Type here"
                    width="100%"
                    {...register("address1")}
                  />
                  <span className="text-red-600">
                    {errors.address1?.message}
                  </span>
                </div>
              </div>
              <div className="w-full md:w-[360px] lg:w-[200px] xl:w-[250px] 2xl:w-[320px]">
                <label
                  htmlFor="addressLine2"
                  className="text-base font-normal text-gilroy-medium"
                >
                  Address Line 2
                </label>
                <div className="mt-2">
                  <TextArea
                    placeholder="Type here"
                    width="100%"
                    {...register("address2")}
                  />
                  {/* <span className="text-red-600">
                    {errors.address2?.message}
                  </span> */}
                </div>
              </div>
              <div className="w-full md:w-[360px] lg:w-[200px] xl:w-[250px] 2xl:w-[320px] mt-2">
                <label
                  htmlFor="pinCode"
                  className="text-base font-normal text-gilroy-medium"
                >
                  PIN Code <span className="text-red-500 -ml-1">*</span>
                </label>
                <div className="">
                  <InputFields
                    placeholder="Type here"
                    {...register("pincode")}
                  />
                  <span className="text-red-600">
                    {errors.pincode?.message}
                  </span>
                </div>
              </div>
              <div className="w-full md:w-[360px] lg:w-[200px] xl:w-[250px] 2xl:w-[320px] mt-2">
                <label
                  htmlFor="State"
                  className="text-base font-normal text-gilroy-medium"
                >
                  State<span className="text-red-500">*</span>
                </label>
                <InputFields
                  // placeholder=""
                  {...register("stateId")}
                  onChange={handleStateChange}
                  disabled
                  placeholder="Select"
                />
                {/* <SelectButton
                  setOption={handleSelectState}
                  options={states}
                  selectedOption={selectedState}
                  // placeholder="Select"
                  width="100%"
                /> */}
                <span className="text-red-600">{errors.stateId?.message}</span>
              </div>
              <div className="w-full md:w-[360px] lg:w-[200px] xl:w-[250px] 2xl:w-[320px] mt-2">
                <label
                  htmlFor="State"
                  className="text-base font-normal text-gilroy-medium"
                >
                  District<span className="text-red-500">*</span>
                </label>
                <InputFields
                  placeholder=""
                  {...register("districtId")}
                  onChange={handleDistrictChange}
                  disabled
                />
                {/* <SelectButton
                  setOption={handleSelectDistrict}
                  options={districts}
                  selectedOption={selectedDistrict}
                  placeholder="Select"
                  width="100%"
                /> */}
                <span className="text-red-600">
                  {errors.districtId?.message}
                </span>
              </div>
              <div className="w-full md:w-[360px] lg:w-[200px] xl:w-[250px] 2xl:w-[320px]">
                <label
                  htmlFor="State"
                  className="text-base font-normal text-gilroy-medium"
                >
                  Jurisdiction<span className="text-red-500">*</span>
                </label>
                <SelectButton
                  setOption={handleJuridictionSelect}
                  options={states}
                  selectedOption={selectedJuridiction}
                  placeholder="Select"
                  width="100%"
                />
                <span className="text-red-600">
                  {errors.jurisdictionId?.message}
                </span>
              </div>

              <div className="col-span-1 lg:col-span-3 flex justify-between items-center  p-2  mt-14 mb-2">
                <div
                  className="flex items-center hover:cursor-pointer"
                  onClick={() => {
                    navigate("/cms/master/authority");
                  }}
                >
                  <img
                    src={ArrowIcon}
                    alt="ArrowIcon"
                    className="w-6 h-6 mr-2"
                  />
                  <h1 className="text-sm font-normal text-black">Back</h1>
                </div>
                <div className="flex-grow"></div>{" "}
                <div className="">
                  <Button
                    type="submit"
                    label="Save"
                    width="100px"
                    loader={loader}
                  />
                </div>
              </div>
              {showSuccessPopup && (
                <AdditionSuccessfulModalOne
                  heading={successData.heading}
                  paragraph={successData.paragraph}
                  onClose={handleClosePopup}
                  onSave={handleSucessSavePopup}
                  logo={successData.logo}
                />
              )}
            </form>
          </div>
        </div>
      }
    />
  );
};

export default CmsMasterCAuthorityForm;
