import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import ReactTable from "../../components/common/ReactTable";
import ActionButton from "../../components/common/ActionButton";
import ToggleSwitch from "../../components/common/ToggleSwitch";
import { createColumnHelper } from "@tanstack/react-table";
import UmTabs from "../../components/UserManagement/UmTabs";
import UploadButton from "../../components/common/UploadButton";
import searchButton from "../../assets/images/search-normal.svg";
import addCircle from "../../assets/images/add-circleb.svg";
import CustomPagination from "../../components/cms/Pagination/CustumPagination";
import SelectButtonUserManagement from "../../components/UserManagement/SelectButtonUserManagement";
import { Link, useLocation, useNavigate } from "react-router-dom";
import InputFields from "../../components/form/InputField";
import { axiosUAMInstance } from "../../utils/axios";
import LoaderSpin from "../../components/LoaderSpin";
import SendActivationLink from "../../components/UserManagement/SendActivationLink";

type TableType = {
  sno: number;
  id: number;
  firstName: string;
  role: string;
  lastName: string;
  isActive: boolean;
  middleName: string;
};

const columnHelper = createColumnHelper<TableType>();

const UserCreation = () => {
  const [selectedFunc, setSelectedFunc] = useState<string | null>(null);
  const [cData, setCdata] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [resetSearch, setResetSearch] = useState(false);

  const [total, setTotal] = useState(0);
  const location = useLocation();

  const [loader, setLoader] = useState<boolean>(false);
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [optionData, setOptionData] = useState([]);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  console.log(currentPage, "currentPage");

  useEffect(() => {
    getUserCreation();
  }, [page, pageSize]);

  const getUserCreation = () => {
    setLoader(true);
    axiosUAMInstance
      .get(`/admin/user/list`, {
        params: {
          search: searchQuery,
          roleName: selectedFunc,
          page: page,
          pageSize: pageSize,
        },
      })
      .then((response) => {
        console.log({ response });

        setLoader(false);
        setCdata(response?.data?.data?.userList);
        setTotalItems(response?.data?.data?.totalPages || 0);
        setCurrentPage(response?.data?.data?.currentPage || 0);
        setTotal(response?.data?.data?.totalData);
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
      });
  };
  const getAdminRoleList = () => {
    axiosUAMInstance
      .get(`/admin/role/list`, {
        params: {
          page: 1,
          pageSize: 100,
        },
      })
      .then((response) => {
        // console.log(response?.data.roles, "admin/role/list");
        setOptionData(
          response?.data?.roles?.map((f: any) => ({
            value: f.id,
            label: f.compositeRoleName,
          }))
        );
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getAdminRoleList();
  }, [resetSearch]);

  const handleSetFunc = (data: any) => {
    setSelectedFunc(data.label);
  };

  const handleSearch = (e: any) => {
    if (e.target.value === "") {
      setPage(1);
      setSearchQuery("");
      setResetSearch(!resetSearch);
    }
    setSearchQuery(e.target.value);
  };

  let count = (page - 1) * pageSize;

  const columns = [
    columnHelper.accessor("sno", {
      cell: () => {
        while (count <= total) {
          count++;
          return count;
        }
      },
      header: () => <span className="font-[400]">S.No.</span>,
    }),
    columnHelper.accessor("firstName", {
      // cell: (info) => info.renderValue(),
      cell: (info) => {
        const firstName = info.row.original.firstName;
        const lastName = info.row.original.lastName;
        const middleName = info.row.original.middleName;

        return (
          <div>
            <span>
              {firstName} &nbsp;
              {middleName && middleName} &nbsp;{lastName}
            </span>
          </div>
        );
      },
      header: () => <span className="font-[400]">Username</span>,
    }),
    columnHelper.accessor("role", {
      cell: (info) => info.renderValue(),
      header: () => <span className="font-[400]">Role</span>,
    }),
    // columnHelper.accessor("middleName", {
    //   cell: (info) => info.renderValue(),
    //   header: () => <span className="font-[400]">Middle Name</span>,
    // }),
    columnHelper.accessor("isActive", {
      cell: (info) => {
        // const value: boolean = info.getValue();
        // console.log(value, "value");
        const value = info?.row?.original?.isActive;
        const id = info?.row?.original?.id;
        const apiCall = () => {
          axiosUAMInstance
            .patch(`admin/user/status/`, {
              id: id,
              status: !value,
            })
            .then((response: any) => {
              // console.log(response?.data?.data?.isActive, "usercreation");
              getUserCreation();
            })
            .catch((error: any) => {});
        };
        return (
          <div
            className="flex flex-col md:flex-row justify-center gap-3"
            key={Math.random()}
          >
            <span> {value ? "Active" : "In Active"}</span>
            <ToggleSwitch enabled={value} apiCall={apiCall} />
          </div>
        );
      },
      header: () => <span className="font-[400]">Status</span>,
    }),
    columnHelper.accessor((row) => row, {
      id: "link",
      cell: (info) => {
        const value: any = info.getValue();
        return (
          <div className="flex justify-center">
            {value?.emailId && <SendActivationLink email={value?.emailId} />}
          </div>
        );
      },
      header: () => <span>Send Invite Link</span>,
    }),
    columnHelper.accessor((row) => row, {
      id: "action",
      cell: (info) => {
        const value = info.getValue();
        // console.log(info.row.original, "info in user creation");
        const userData = info?.row?.original;
        return (
          <div className="flex flex-row justify-center">
            {/* <Link to={`/usermanagement/adduser`}>
              <ActionButton variant="edit" />
            </Link> */}
            <div
              onClick={() => {
                navigate("/usermanagement/edituser", {
                  state: { userData, currentPage: currentPage },
                });
              }}
            >
              <ActionButton variant="edit" />
            </div>
          </div>
        );
      },
      header: () => <span className="font-[400]">Edit</span>,
    }),
  ];

  const handleSubmit = () => {
    setLoader(true);
    setPage(1);
    getUserCreation();
  };
  useEffect(() => {
    const currentPageFromState = location.state?.currentPage;
    if (currentPageFromState) {
      setPage(currentPageFromState);
    } else {
      setPage(1); // default to the first page
    }
  }, [location.state?.currentPage]);

  console.log({ cData });

  return (
    <div>
      <Layout
        layout={
          <div
            className="relative mx-4 xl:ml-[40px]"
            style={{ minHeight: "calc(100vh - 110px)" }}
          >
            <div className="mt-4 ">
              <UmTabs />
            </div>
            <div className="mt-[27px] mb-[35px] flex items-center  flex-wrap gap-4">
              <div className="mt-2">
                <InputFields
                  height="48px"
                  width="500px"
                  padding="10px"
                  placeholder="Search by Name/Role"
                  onChange={handleSearch}
                  onEnterPress={handleSubmit}
                />
              </div>
              <div className="w-[180px]">
                <SelectButtonUserManagement
                  setOption={handleSetFunc}
                  options={optionData || []}
                  selectedOption={selectedFunc}
                  placeholder="Role"
                />
              </div>
              <button
                onClick={handleSubmit}
                // disabled={!searchQuery && !selectedFunc}
                className={`w-40 h-[45px] border-[2px] rounded-[8px] py-[10.5px] px-2 xl:px-[16px] flex justify-center items-center ${
                   "bg-[#1C468E] cursor-pointer border-[#1C468E]"
                } mt-2`}
              >
                <img src={searchButton} alt="searchButton" />
                <span className="ml-1 text-[14px] md:text-base font-normal text-[#fff] lg:text-[16px] text-gilroy-medium ">
                  Search
                </span>
              </button>
              <Link to="/usermanagement/adduser">
                <div className="w-44 h-[40px] border-[1px] rounded-[8px] py-[10.5px] px-2 xl:px-[16px] border-[#1C468E] flex justify-center items-center mt-2 cursor-pointer">
                  <img src={addCircle} alt="icon" />
                  <span className="ml-1 text-[14px] md:text-base font-normal text-[#1C468E] lg:text-[16px] text-gilroy-medium ">
                    Add User
                  </span>
                </div>
              </Link>
            </div>

            <div className="h-screen md:h-auto sm:h-auto overflow-x-hidden overflow-y-auto">
              <div className="mb-20">
                {loader ? (
                  <LoaderSpin />
                ) : cData && cData.length > 0 ? (
                  <ReactTable
                    key={JSON.stringify(cData)}
                    defaultData={cData}
                    columns={columns}
                  />
                ) : (
                  <>
                    <div className="flex justify-center items-center mt-5">
                      <p className="text-xl text-gilroy-mediumItalic ">
                        {searchQuery?.length > 0
                          ? "No Record Found"
                          : " No Data Available"}
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="mt-10 absolute bottom-0">
              {cData.length > 0 && (
                <CustomPagination
                  currentPage={page}
                  setCurrentPage={setPage}
                  totalItems={total}
                  itemsPerPage={pageSize}
                  maxPageNumbersToShow={5}
                />
              )}
            </div>
          </div>
        }
      />
    </div>
  );
};

export default UserCreation;
