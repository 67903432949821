import axios from "axios";
import { pincodeValidationUrl } from "./api";
import { axiosUAMInstance } from "../utils/axios";

const dateFormattor = (date: Date) => {
  // Ensure the input is a Date object
  if (!(date instanceof Date)) date = new Date(date);

  let month = "" + (date.getMonth() + 1), // Months are zero-based
    day = "" + date.getDate(),
    year = date.getFullYear();

  // If day or month are less than 10, prepend with 0
  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year].join("-");
};

export const fetchStateAndDistrict = async (
  pincode: string
): Promise<{
  stateValue: string;
  districtValue: string;
  error: boolean;
}> => {
  try {
    const response = await axiosUAMInstance.get(`${pincodeValidationUrl}/${pincode}`);
    const data = response?.data;
    if (data?.length > 0 && data[0]?.PostOffice?.length > 0) {
      let locationData = data[0]?.PostOffice[0];
      return {
        stateValue: locationData?.State,
        districtValue: locationData?.District,
        error: false,
      };
    } else {
      return {
        stateValue: "",
        districtValue: "",
        error: true,
      };
    }
  } catch (error) {
    return {
      stateValue: "",
      districtValue: "",
      error: true,
    };
  }
};

function debounce<T extends (...args: any[]) => void>(func: T, wait: number) {
  let timeout: ReturnType<typeof setTimeout>;
  return (...args: Parameters<T>) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func(...args);
    }, wait);
  };
}
const toCamelCase = (inputString: string): string => {
  // Split the string by non-letter characters and handle existing camelCase by inserting spaces before capital letters in the middle of words
  let words = inputString.split(/[^a-zA-Z]+/).reduce<string[]>((acc, word) => {
    return acc.concat(word.split(/(?=[A-Z])/));
  }, []);

  return words
    .map((word, index) => {
      let lowerWord = word.toLowerCase();
      if (index === 0) {
        return lowerWord;
      } else {
        return lowerWord.charAt(0).toUpperCase() + lowerWord.slice(1);
      }
    })
    .join("");
};

const getSectionsKey = (section: string) => {
  let key = "";
  if (section === "Helpdesk Details") {
    key = "helpDeskDetails";
  } else if (section === "Helpdesk Heading") {
    key = "heldDeskHeading";
  } else if (section === "Heading") {
    key = "heading";
  } else if (section === "Name Label") {
    key = "nameLabel";
  } else if (section === "Email Label") {
    key = "emailLabel";
  } else if (section === "Address Label") {
    key = "addressLabel";
  } else if (section === "Address Details") {
    key = "addressDetails";
  } else if (section === "Address Heading") {
    key = "addressHeading";
  } else if (section === "Phone Number Label") {
    key = "phoneNumberLabel";
  } else if (section === "Compliance Officer Details") {
    key = "complainceOfficerDetails";
  } else if (section === "Compliance Officer Heading") {
    key = "complainceOfficerHeading";
  } else if (section === "Residential Process Details") {
    key = "residentailProcessDetails";
  } else if (section === "Residential Process Heading") {
    key = "residentailProcessHeading";
  } else if (section === "CKYC Registration Queries Details") {
    key = "ckycRegistrationQueriesDetails";
  } else if (section === "CKYC Registration Queries Heading") {
    key = "ckycRegistrationQueriesHeading";
  } else {
    key = "";
  }
  return key;
};

function getMonthAbbreviation(monthNumber: number): string {
  switch (monthNumber) {
    case 1:
      return "Jan";
    case 2:
      return "Feb";
    case 3:
      return "Mar";
    case 4:
      return "Apr";
    case 5:
      return "May";
    case 6:
      return "Jun";
    case 7:
      return "Jul";
    case 8:
      return "Aug";
    case 9:
      return "Sep";
    case 10:
      return "Oct";
    case 11:
      return "Nov";
    case 12:
      return "Dec";
    default:
      throw new Error("Invalid month number. Must be between 1 and 12.");
  }
}

export { dateFormattor, debounce, toCamelCase, getSectionsKey,getMonthAbbreviation };
