import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import InputFields from "../../components/form/InputField";
import UploadButton from "../../components/common/UploadButton";
import ReactTable from "../../components/common/ReactTable";
import CustomPagination from "../../components/cms/Pagination/CustumPagination";
import { createColumnHelper } from "@tanstack/react-table";
import Eye from "../../assets/images/eye2.svg";
import Download from "../../assets/images/Download-icon.png";
import SortIcon from "../../assets/images/arrange-square-2.svg";
import searchButton from "../../assets/images/search-normal.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SelectButtonSchemeMaster from "../../components/SchemaMaster/SelectButtonSchemeMaster";
import { axiosUAMInstance } from "../../utils/axios";
import LoaderSpin from "../../components/LoaderSpin";
import moment from "moment";
import SelectButtonEntityMaster from "../../components/EntityMaster/SelectButtonEntityMaster";

type TableType = {
  id: Number;
  uniqueId: string;
  name: string;
  status: string;

  depositTakerId: string;

  createdAt: Date;
  createdByName: string;
  depositTakerName: string;
};
const options = [
  { value: "", label: "All" },
  { value: "ACTIVE", label: "Active" },
  {
    value: "ACTIVE_DEPOSIT_NOT_TAKEN",
    label: "Active-Deposit not being taken",
  },
  { value: "BANNED", label: "Banned" },
  { value: "UNDER_LETIGATION", label: "Under litigation" },
];
const columnHelper = createColumnHelper<TableType>();

const SchemeMaster = () => {
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
  const [loader, setLoader] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);
  const [schemeData, setSchemeData] = useState([]);
  const [statusForSearch, setStatusForSearch] = useState<string | null>(null);
  const location = useLocation();
  const [searchButtonClick, setSearchButtonClick] = useState<boolean>(false);

  const [searchInput, setSearchInput] = useState<string>("");

  // const handleSearchInput = (event: any) => {
  //   event?.preventDefault();
  //   const { value } = event?.target;
  //   setSearchInput(value);
  //   if (value === "") {
  //     apiCall();
  //   }
  // };
  const handleSearchInput = (event: any) => {
    event?.preventDefault();
    const { value } = event?.target;
    setSearchInput(value);
  };

  useEffect(() => {
    if (searchInput === "") {
      apiCall();
    }
  }, [searchInput]);

  const navigate = useNavigate();
  const apiCall = () => {
    setLoader(true);
    axiosUAMInstance
      .get(`/scheme-portal/scheme`, {
        params: {
          page: searchButtonClick?1:page,
          limit: pageSize,
          searchText: searchInput,
          status: statusForSearch,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          // console.log(res.data?.data);
          setSchemeData(res.data?.data);

          // console.log(res?.data?.total);
          setTotal(res?.data?.total);
        }
        setLoader(false);
        setSearchButtonClick(false);
      })
      .catch((error) => {
        console.log(error.message);
        setSchemeData([]);
        setLoader(false);
        setSearchButtonClick(false);
      });
  };
  useEffect(() => {
    apiCall();
  }, [pageSize, page, statusForSearch]);
  const NavigateSchemeForm = (id: string) => {
    navigate("/schememaster/form", {
      state: {
        schemeId: id,
      },
    });
  };
  let count: number;
  const serialNoGen = (page: number) => {
    count = (page - 1) * 10;
  };
  serialNoGen(page);

  const columns = [
    columnHelper.accessor("id", {
      cell: () => {
        while (count <= total) {
          count++;
          return count;
        }
      },
      header: () => <span>S.No.</span>,
    }),
    columnHelper.accessor("uniqueId", {
      cell: (info) => info.renderValue(),
      header: () => <span>Scheme ID</span>,
    }),
    columnHelper.accessor("name", {
      cell: (info) => info.renderValue(),
      header: () => <span>Scheme Name</span>,
    }),
    columnHelper.accessor("status", {
      cell: (info: any) => {
        let value = info?.getValue();

        // Replace underscores with spaces and handle specific status cases
        if (value === "ACTIVE_DEPOSIT_NOT_TAKEN") {
          value = "Active-Deposit not being taken";
        } else if (value === "UNDER_LETIGATION") {
          value = "UNDER LITIGATION";
        } else if (value && /mod_transit/i.test(value)) {
          // Check for specific combination of "MOD" and "TRANSIT"
          value = "Modification in Transit";
        } else if (value && /mod/i.test(value)) {
          // Case-insensitive replacement of "MOD" with "Modification"
          value = value.replace(/mod/i, "Modification");
        } else {
          // Default replacement of underscores with spaces
          value = value?.replace(/_/g, " ");
        }

        return (
          <div
            className="flex flex-col md:flex-row justify-center gap-3"
            key={Math.random()}
          >
            <span className="text-sm">{value || "N/A"}</span>
          </div>
        );
      },
      header: () => <span>Status</span>,
    }),

    // columnHelper.accessor("status", {
    //   header: () => "Status",

    //   cell: (info: any) => {

    //     let value = info?.getValue();

    //     // Replace underscores with spaces and handle the specific status case
    //     if (value === "ACTIVE_DEPOSIT_NOT_TAKEN") {
    //       value = "Active-Deposit not being taken";
    //     } else if (value === "UNDER_LETIGATION") {
    //       value = "UNDER LITIGATION";
    //     } else {
    //       value = value?.replace(/_/g, " ");
    //     }

    //     return (
    //       <div
    //         className="flex flex-col md:flex-row justify-center gap-3"
    //         key={Math.random()}
    //       >
    //         <span className="text-sm">{value}</span>
    //       </div>
    //     );
    //   },
    // }),
    columnHelper.accessor("depositTakerName", {
      cell: (info) => info.renderValue(),
      header: () => <span>Deposit Taker</span>,
    }),
    columnHelper.accessor("createdAt", {
      cell: (info) => moment(info.getValue()).format("DD-MM-YYYY"),
      header: () => <span>Action Date</span>,
    }),
    columnHelper.accessor((row: any) => row, {
      id: "action",
      cell: (info: any) => {
        let createdBy = info?.cell?.row?.original?.createdBy;
        const uniqueId = info?.row?.original?.uniqueId;
        const depositTakerId = info?.row?.original?.depositTakerId;
        console.log("dtid", info);
        const NavigateScheme = (
          uniqueId: any,
          depositTakerId: any,
          page: any
        ) => {
          navigate("/schememaster/form", {
            state: {
              uniqueId: uniqueId,
              depositTakerId: depositTakerId,
              createdBy,
              pages: page,
            },
          });
        };
        return (
          <div className="flex justify-center items-center ">
            {/* <Link to={"/dt/schema/creation"}> */}
            <div onClick={() => NavigateScheme(uniqueId, depositTakerId, page)}>
              <img src={Eye} alt="Eye " className="cursor-pointer" />
            </div>
            {/* </Link> */}
          </div>
        );
      },
      header: () => <span>View</span>,
    }),
  ];

  const [selectedOption1, setSelectedOption1] = useState<string | null>(null);
  const [selectedOption2, setSelectedOption2] = useState<string | null>(null);
  const [selectedOption3, setSelectedOption3] = useState<string | null>(null);

  const handleSetOption1 = (value: string) => {
    setSelectedOption1(value);
  };

  const handleSetOption2 = (value: string) => {
    setSelectedOption2(value);
  };

  const handleSetOption3 = (value: string) => {
    setSelectedOption3(value);
  };

  const handleSetStatus = (option: any) => {
    setPage(1);
    setSelectedStatus(option);
    setStatusForSearch(option?.value);
  };

  const handleClickSearch = () => {
    setSearchButtonClick(true);
    setPage(1);
    apiCall();
  };
  useEffect(() => {
    const currentPageFromState = location.state?.currentPage;
    console.log(currentPageFromState, "currentPageFromState");

    if (currentPageFromState) {
      apiCall();
      setPage(currentPageFromState);
    } else {
      setPage(1); // default to the first page
    }
  }, [location.state?.currentPage]);
  return (
    <div>
      <Layout
        layout={
          <div
            className="relative mx-4 xl:ml-[40px]"
            style={{ minHeight: "calc(100vh - 110px)" }}
          >
            <div className=" mt-2">
              <div className=" flex  space-x-2  items-center flex-wrap">
                <div className="md:w-[500px] lg:w-[600px] sm:w-[350px] w-[300px]">
                  <div className="mb-2">
                    <label
                      htmlFor="Deposit taker Search"
                      className="text-base font-normal text-gilroy-medium "
                    >
                      Scheme Search
                    </label>
                  </div>
                  <InputFields
                    onChange={handleSearchInput}
                    height="40px"
                    padding="10px"
                    placeholder="Search by Unique ID/name"
                    value={searchInput}
                  />
                </div>
                <div className=" flex items-center mt-7">
                  <div className=" w-44">
                    <div
                      className="relative w-full"
                      onClick={handleClickSearch}
                    >
                      <input
                        className={
                          "top-2 left-4 z-10 w-0 h-0 form-input border flex border-gray-300 text-gray-600 rounded-md focus:outline-none justify-between align-middle]"
                        }
                        type="file"
                        id="inputButton"
                      />
                      <button
                        className={
                          " top-0 w-full z-20 bg-[#1c468e] text-white form-input border px-[16px] py-[8px] flex   rounded-[8px] focus:outline-none focus:ring-1 focus:ring-gray-100 justify-center align-middle overflow-hidden"
                        }
                      >
                        <img
                          src={searchButton}
                          alt=""
                          className="mr-1 rounded-lg"
                        />
                        Search
                      </button>
                    </div>
                  </div>
                </div>
                {/* <div className=" flex items-center mt-7">
                  <div className=" w-44">
                    <UploadButton filename="Download" imageSrc={Download} />
                  </div>
                </div> */}
              </div>
            </div>
            <div>
              <div className="mt-[25px] mb-[35px] ">
                <div className="">
                  <p className="text-sm font-normal text-gilroy-medium ">
                    OR search by Status
                  </p>
                </div>
                <div className="flex items-center flex-wrap gap-4">
                  {/* <div className="">
                    <SelectButtonSchemeMaster
                      setOption={handleSetOption1}
                      options={options}
                      selectedOption={selectedOption1}
                      placeholder="India"
                      borderColor={"#1C468E"}
                    />
                  </div>
                  <div className="">
                    <SelectButtonSchemeMaster
                      setOption={handleSetOption2}
                      options={options}
                      selectedOption={selectedOption2}
                      placeholder="Maharashtra"
                      borderColor={"#1C468E"}
                    />
                  </div>
                  <div className="">
                    <SelectButtonSchemeMaster
                      setOption={handleSetOption2}
                      options={options}
                      selectedOption={selectedOption2}
                      placeholder="Pune"
                      borderColor={"#1C468E"}
                    />
                  </div> */}
                  {/* <div className="h-6 border-r-2 border-gray-300 "></div> */}
                  <div className="z-20">
                    <SelectButtonEntityMaster
                      setOption={handleSetStatus}
                      options={options}
                      selectedOption={selectedStatus}
                      placeholder="Status"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="h-screen md:h-auto sm:h-auto overflow-x-hidden overflow-y-auto">
                <div className="mb-20">
                  {loader ? (
                    <LoaderSpin />
                  ) : schemeData?.length > 0 ? (
                    <ReactTable
                      key={JSON?.stringify(schemeData)}
                      defaultData={schemeData}
                      columns={columns}
                    />
                  ) : (
                    <div className=" flex justify-center items-center">
                      <h1>No data available</h1>
                    </div>
                  )}
                </div>
              </div>
              <div className="w-full absolute bottom-0">
                {schemeData?.length > 0 && (
                  <CustomPagination
                    currentPage={page}
                    setCurrentPage={setPage}
                    totalItems={total}
                    itemsPerPage={pageSize}
                    maxPageNumbersToShow={5}
                  />
                )}
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default SchemeMaster;
