import React, { useState, useEffect } from "react";
import Layout from "../../components/layout/Layout";
import DscKeyLogin from "../../components/form/DscKeyLogin";
import Swal from "sweetalert2";
import TaskTabs from "../../components/taskTabs/TaskTabs";
import Footer from "../../components/common/Footer";
import { useScreenWidth } from "../../utils/ScreenSize";
import { axiosUAMInstance } from "../../utils/axios";

import { convertFileToBase64 } from "../../utils/fileConversion";
import DscButton from "../../components/form/Dscbutton";

const UpdateDSC3 = () => {
  const screenWidth = useScreenWidth();
  const customClass = "flex flex-col w-full mt-4 justify-between w-full";
  const [isDscSelected, setDscSelected] = useState<boolean>(false);
  const [dscCertificate, setDscCertificate] = useState<any>();
  const [isError, setError] = useState(false);
  const [loader, setLoader] = useState(false);
  const userId = sessionStorage.getItem("userId");
  const [base64Data, setBase64Data] = useState<string>("");
  const [hexData, setHexData] = useState("");
  const [fileName, setFileName] = useState<string | undefined>("");
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const isDscKeyAvbl = window.appConfig.REACT_APP_IS_DSC_KEY_AVBL;

  useEffect(() => {
    console.log(isError);
    if (isDscSelected) {
      setError(false);
    }
  }, [isDscSelected]);

  const verifyDscWithNodalOfficer = () => {
    const firstName = sessionStorage.getItem("firstName")?.toUpperCase();
    const lastName = sessionStorage.getItem("lastName")?.toUpperCase();
    const middleName = sessionStorage.getItem("middleName")?.toUpperCase();

    if (!firstName || !lastName) {
      return false;
    }

    const dscCertName =
      dscCertificate?.SelCertSubject?.split(",")[0]?.toUpperCase();
    console.log(dscCertName, "dsc update name");

    if (!dscCertName) {
      return false;
    }

    // Extract and normalize names from the certificate name
    const certNameParts = dscCertName
      .replace("CN=", "")
      .toUpperCase()
      .split(" ")
      .filter(Boolean);

    // Combine names into a single array
    // const combinedNames = [firstName, middleName, lastName].sort();
    const combinedNames = [firstName, middleName, lastName]
      .filter((name) => name)
      .sort();

    const certNameSorted = certNameParts.sort();
    // Check if all parts of combined names are present in the certificate name
    const isMatch =
      combinedNames.length === certNameSorted.length &&
      combinedNames.every((part, index) => part === certNameSorted[index]);
    return isMatch;
  };
  const handleFileUpload = (file: File | null) => {
    setFileName(file?.name);
    if (file) {
      setIsFileUploaded(true);

      convertFileToBase64(
        file,
        (hex) => {
          setHexData(hex);
        },
        (base64) => {
          setBase64Data(base64);
        }
      );
    } else {
      setIsFileUploaded(false);
      setBase64Data(""); //    Clear the base64 data if no file is uploaded
      setHexData(""); //    Clear the hex data as well
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    // if (!isDscSelected) {
    //   setError(true);
    //   return;
    // }
    // console.log(dscCertificate, "clicked dsc3 submit");
    if (!isDscSelected && !fileName) {
      setError(true);
      return;
    }

    if (!verifyDscWithNodalOfficer() && isDscKeyAvbl === "true") {
      Swal.fire({
        icon: "error",
        title: "Invalid Name",
        text: "Nodal Officer name should match with DSC3",
      });
      return;
    }

    try {
      setLoader(true);
      const response = await axiosUAMInstance.put(`/admin/user/updatedsc`, {
        id: Number(userId),
        // dscCertificate: dscCertificate,
        dscCertificate: isDscKeyAvbl === "true" ? dscCertificate : base64Data,
        // dscCertificate: btoa(dscCertificate?.Cert),
      });
      console.log(response?.data);
      setLoader(false);

      Swal.fire({
        icon: "success",
        title: response?.data?.message || "DSC3 Updated Successfully",
        text: "",
        customClass: {
          container: "my-swal",
        },
      });
    } catch (error: any) {
      console.error("Error updating DSC:", error);
      setLoader(false);

      Swal.fire({
        icon: "error",

        title: error?.response?.data?.message || "Failed to Update DSC",
        text: "",
        customClass: {
          container: "my-swal",
        },
      });
    }
  };

  return (
    <div>
      <Layout
        layout={
          <>
            <div className="mt-6 mx-6">
              <TaskTabs />
            </div>
            <div className={customClass}>
              <form
                className="flex flex-col justify-between p-6"
                onSubmit={handleSubmit}
                style={{
                  height: `${
                    screenWidth > 1024 ? "calc(100vh - 170px)" : "100%"
                  }`,
                }}
              >
                <div className="w-[100%] md:w-[35%]">
                  {/* <h1
                className={`w-[130px] hover:text-gilroy-bold text-[20px]  font-bold text-[#1C468E] border-b-4 border-[#1C468E] mb-5`}
              >
                Update DSC3
              </h1> */}
                  {/* <TaskTabs /> */}

                  <>
                    {isDscKeyAvbl === "false" ? (
                      <DscButton
                        onFileUpload={handleFileUpload}
                        disabled={false}
                        fileName={fileName}
                      >
                        Upload Document
                      </DscButton>
                    ) : (
                      <DscKeyLogin
                        setDscSelected={setDscSelected}
                        isDscSelected={isDscSelected}
                        setDscCertificate={setDscCertificate}
                      />
                    )}
                  </>
                </div>
                <div>
                  <Footer loader={loader} label="Save and Continue" />
                </div>
                {/* <div className="flex items-center self-end">
              <button
                disabled={false}
                onClick={handleSubmit}
                type="button"
                className={`${
                  false ? "bg-gray-500" : "bg-[#1C468E]"
                } rounded-xl p-3 text-white font-semibold text-sm w-full sm:w-auto sm:max-w-xs`}
              >
                {loader ? LoaderSpin() : " Save and Continue"}
              </button>
            </div> */}
              </form>
            </div>
          </>
        }
      />
    </div>
  );
};

export default UpdateDSC3;
