import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import ErrorCircle from "../../assets/images/error-circle.svg";
import add from "../../assets/images/add.svg";
import TextArea from "../common/TextArea";
import SelectButton from "../form/SelectButton";
import ButtonComp from "../cms/ButtonComp";
import { axiosTraceIdInstance, axiosUAMInstance } from "../../utils/axios";
import { useLocation, useNavigate } from "react-router-dom";

interface ReturnModelPopupProps {
  onClose: () => void;
  onSave: () => void;
  Id: string;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 4,
};

const ApprovePopupRegister: React.FC<ReturnModelPopupProps> = ({
  onClose,
  onSave,
  Id,
}) => {
  const handleCloseModal = () => {
    onClose();
  };

  const location = useLocation();

  const [loader, setLoader] = useState<boolean>(false);
  const [isApiSucess, setApiSuccess] = useState<boolean>(false);
  const [isApiError, setApiError] = useState<boolean>(false);
  const [checklist, setChecklist] = useState([]);
  const [checkerId, setCheckerId] = useState<number | null>(null);
  const [selectedFunc, setSelectedFunc] = useState<string | null>(null);
  const [selectedFuncChecker, setSelectedFuncChecker] = useState<string | null>(
    null
  );
  const [searchInputValue1, setSearchInputValue1] = useState<string>("");
  const [isCheckerSelected, setCheckerSelected] = useState<boolean>(false);

  const regulatorId = location.state?.regulatorId;
  const status = location?.state?.status;

  const userid = sessionStorage.getItem("userId");
  const firstName = sessionStorage.getItem("firstName");
  const lastName = sessionStorage.getItem("lastName");
  const navigate = useNavigate();

  // const apiCallChecker = () => {
  //   axiosUAMInstance
  //     .get("/admin/user/checker")
  //     .then((response: any) => {
  //       if (response.data.success) {
  //         console.log(response?.data?.data, "response");
  //         setChecklist(
  //           response?.data?.data?.map((f: any) => ({
  //             value: f.id,
  //             // label: f.name,
  //             label: `${f.firstName} ${f.lastName}`,
  //           }))
  //         );
  //       }
  //     })
  //     .catch((err) => {});
  // };
  const apiCallChecker = () => {
    axiosUAMInstance
      .get("/admin/user/checker")
      .then((response: any) => {
        if (response.data.success) {
          const currentUserFirstName = sessionStorage.getItem("firstName");
          const currentUserLastName = sessionStorage.getItem("lastName");
          setChecklist(
            response?.data?.data
              .filter(
                (f: any) =>
                  f.firstName !== currentUserFirstName ||
                  f.lastName !== currentUserLastName
              )
              .map((f: any) => ({
                value: f.id,
                label: `${f.firstName} ${f.lastName}`,
              }))
          );
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    apiCallChecker();
  }, []);

  const handleStatus = (currentStatus: string) => {
    switch (currentStatus) {
      case "TRANSIT":
        return true;
      case "MOD_TRANSIT":
        return true;
      default:
        return false;
    }
  };

  const getNextStatus = (currentStatus: string) => {
    switch (currentStatus) {
      case "MOD_PENDING":
        return "MOD_TRANSIT";
      case "MOD_TRANSIT":
        return "APPROVED";
      case "PENDING":
        return "TRANSIT";
      case "TRANSIT":
        return "APPROVED";
      default:
        return currentStatus;
    }
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // if (!checkerId) {
    //   return; // Prevents the form submission if no checker is selected
    // }
    if (!checkerId && !handleStatus(status)) {
      return; // Only prevent submission if status is not "TRANSIT" and no checker is selected
    }

    try {
      setLoader(true);
      const response = await axiosUAMInstance.post(
        `/approval-engine/update-status`,
        {
          uniqueId: Id,
          // status: status === "PENDING" ? "TRANSIT" : "APPROVED",
          status: getNextStatus(status),
          approverId: Number(userid),
          checkerId: checkerId,
        }
      );
      // console.log(response, "response");
      if (response.status === 201) {
        setApiSuccess(true);
        setApiError(false);

        setTimeout(() => {
          onClose();
          onSave();
          navigate(-1);
      
          setLoader(false);
        }, 2500);
      } else {
      }
    } catch (error) {
      setLoader(false);
      setApiError(true);
      setApiSuccess(false);
    }
  };

  const handleSetFuncChecker = (data: any) => {
    setSelectedFuncChecker(data.label);
    setCheckerId(data.value);
  };

  const handleSearchInputChange1 = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchInputValue1(event.target.value);
  };

  return (
    <>
      <Modal
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClose={handleCloseModal}
      >
        <Box sx={style}>
          <div className="md:flex">
            <div className="m-[16px] md:m-16 w-[350px] md:w-[500px] lg:w-[500px] rounded-2xl p-[8px] text-gilroy-medium pb-[32px] shadow-xl bg-white">
              <div
                className="flex flex-row justify-end mb-[12px] cursor-pointer"
                onClick={handleCloseModal}
              >
                <img src={add} className="w-6 h-6" alt="icon" />
              </div>
              <div className=" flex flex-col  justify-center items-center relative">
                <img
                  src={ErrorCircle}
                  alt="ErrorCircle "
                  className="w-12 my-2"
                />
                <h1 className="text-xl font-normal text-gilroy-medium">
                  Are you sure you want to
                </h1>
                <h2 className="text-xl font-normal text-gilroy-medium">
                  approve this application ?
                </h2>
                {isApiSucess && (
                  <p className="text-green-700 absolute top-[120px]">
                    status updated successfully
                  </p>
                )}
                {isApiError && (
                  <p className="text-red-500 absolute top-[120px]">
                    Internal Server Error
                  </p>
                )}
              </div>
              <form onSubmit={handleFormSubmit}>
                <div>
                  <>
                    {!handleStatus(status) && (
                      <div className="w-[300px] md:w-[450px] mx-3 my-4 ">
                        <label
                          htmlFor="State"
                          className="text-base font-normal text-gilroy-medium"
                        >
                          Checker<span className="text-red-500">*</span>
                        </label>
                        <div className="mt-2 relative">
                          <SelectButton
                            setOption={handleSetFuncChecker}
                            options={checklist}
                            selectedOption={selectedFuncChecker}
                            placeholder="Select"
                            searchInputOnchange={handleSearchInputChange1}
                            searchInputValue={searchInputValue1}
                            showSearchInput={false}
                            width="100%"
                          />
                          {isCheckerSelected && (
                            <p className="text-red-500 absolute top-[55px]">
                              Please select checker.
                            </p>
                          )}
                        </div>
                      </div>
                    )}
                  </>{" "}
                  <hr className="w-full bg-[#E6E6E6] mt-[27px] mb-[24px]"></hr>
                  <ButtonComp
                    onClose={onClose}
                    title="Approve"
                    loader={loader}
                    // disabled={!checkerId}
                    disabled={!handleStatus(status) && !checkerId}
                  />
                </div>
              </form>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ApprovePopupRegister;
