import React, { useState, useEffect } from "react";
import Layout from "../../components/layout/Layout";
import InputFields from "../../components/form/InputField";
import UploadButton from "../../components/common/UploadButton";
import ReactTable from "../../components/common/ReactTable";
import CustomPagination from "../../components/cms/Pagination/CustumPagination";
import { createColumnHelper } from "@tanstack/react-table";
import Eye from "../../assets/images/eye2.svg";
import SortIcon from "../../assets/images/arrange-square-2.svg";
import searchButton from "../../assets/images/search-normal.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import EntityMasterTab from "../../components/EntityMaster/EntityMasterTabs";
import SelectButtonEntityMaster from "../../components/EntityMaster/SelectButtonEntityMaster";
import { axiosUAMInstance } from "../../utils/axios";
import LoaderSpin from "../../components/LoaderSpin";
import Swal from "sweetalert2";
import moment from "moment";

type TableType = {
  sno: string;
  uniqueId: string;
  regulatorName: string;
  status: string;
  action: boolean;
  nodalOfficerId: any;
  createdAt: any;
};

const status = [
  { label: "All", value: "" },
  { label: "Approved", value: "APPROVED" },
  // { label: "Banned", value: "BANNED" },
  // { label: "Rejected", value: "REJECTED" },
  { label: "Transit", value: "TRANSIT" },
  // { label: "Incomplete", value: "INCOMPLETE" },
  { label: "Pending", value: "PENDING" },
  { label: "Returned", value: "RETURNED" },
  { label: "Modification Pending", value: "MOD_PENDING" },
  { label: "Modification in Transit", value: "MOD_TRANSIT" },
];

const columnHelper = createColumnHelper<TableType>();

const EntityMasterRegulator = () => {
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);

  const [statusForSearch, setStatusForSearch] = useState<string | null>(null);

  const [searchInput, setSearchInput] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);
  const [taskData, setTaskData] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  const userid = sessionStorage.getItem("userId");

  const apiCall = () => {
    setLoader(true);
    axiosUAMInstance
      .get("/regulator", {
        params: {
          page: page,
          limit: pageSize,
          userId: userid,
          searchText: searchInput,
          status: statusForSearch,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setTaskData(res?.data?.data?.regulators);
          setTotal(res?.data?.data?.total);
        }
        setLoader(false);
      })
      .catch((error: any) => {
        console.log(error.message);
        setLoader(false);
      });
  };

  useEffect(() => {
    apiCall();
  }, [pageSize, page, statusForSearch]);

  let count: number;
  const serialNoGen = (page: number) => {
    count = (page - 1) * 10;
  };
  serialNoGen(page);
  const NavigateRegulator = (
    id: string,
    nodalOfficerId: any,
    createdAt: any,
    page: any
  ) => {
    navigate("/entitymaster/regulator/form", {
      state: {
        regulatorId: id,
        nodalOfficerId: nodalOfficerId,
        createdAt: createdAt,
        page: page,
      },
    });
  };

  const sortName = () => {
    // Create a copy of the taskData array to avoid directly mutating state
    const sortedData = [...taskData].sort((a: any, b: any) => {
      // Normalize strings to lower case to ensure case-insensitive comparison
      const nameA = a.regulatorName?.toLowerCase() || "";
      const nameB = b.regulatorName?.toLowerCase() || "";

      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0; // names are equal
    });

    console.log({ sortedData }, "sortData");

    setTaskData(sortedData);
  };
  const sortStatus = () => {
    const sortedData = [...taskData].sort((a: any, b: any) =>
      a.status?.toLowerCase().localeCompare(b.status?.toLowerCase())
    );
    setTaskData(sortedData);

    console.log({ sortedData }, "sortData");
  };
  const sortByDate = () => {
    const sortedData = [...taskData].sort((a: any, b: any) => {
      const dateA = new Date(a.createdAt); // Assuming 'date' is the key for the date field
      const dateB = new Date(b.createdAt);
      return dateA.getTime() - dateB.getTime(); // Ascending order
    });

    setTaskData(sortedData);
    console.log("sorted by date", sortedData);
  };

  const columns = [
    columnHelper.accessor("sno", {
      cell: () => {
        while (count <= total) {
          count++;
          return count;
        }
      },
      header: () => <span>S.No.</span>,
    }),

    columnHelper.accessor("uniqueId", {
      cell: (info) => (info.renderValue() ? info.renderValue() : "N/A"),
      header: () => <span>Regulator ID</span>,
    }),
    columnHelper.accessor("regulatorName", {
      cell: (info) => (info.renderValue() ? info.renderValue() : "N/A"),
      header: () => (
        <div className="flex justify-center items-center">
          <p>Regulator Name</p>
          <img
            src={SortIcon}
            alt="Regulator Name Icon"
            className="ml-2 cursor-pointer"
            onClick={sortName}
          />
        </div>
      ),
    }),
    columnHelper.accessor("status", {
      header: () => <span>Status</span>,
      cell: (info) => {
        let value = info.renderValue();
        // Check for specific combination of "MOD" and "TRANSIT"
        if (value && /mod_transit/i.test(value)) {
          // Using a case-insensitive regex to match "MOD_TRANSIT"
          value = "Modification in Transit";
        } else if (value && /mod/i.test(value)) {
          // Similarly applying a case-insensitive check for any "MOD" occurrences
          value = value.replace(/mod/i, "Modification"); // Replace "MOD" with "Modification" case-insensitively
        }
        return value ? value.replace(/_/g, " ") : "N/A"; // Replace underscores with spaces for any other statuses
      },
    }),

    // columnHelper.accessor("status", {
    //   cell: (info) => {
    //     // const value = info.renderValue();
    //     // return value ? value.replace(/_/g, " ") : "N/A";
    //     let value = info.renderValue();
    //     // Ensure the check for "MOD" is case-sensitive and replaces it with "Modification"
    //     if (value && value.includes("MOD")) {
    //       value = value.replace("MOD", "Modification");
    //     }
    //     return value ? value.replace(/_/g, " ") : "N/A";
    //   },
    //   header: () => (
    //     <div className="flex justify-center items-center">
    //       <p> Status</p>
    //       <img
    //         src={SortIcon}
    //         alt="Status Icon"
    //         className="ml-2 cursor-pointer"
    //         onClick={sortStatus}
    //       />
    //     </div>
    //   ),
    // }),
    columnHelper.accessor("createdAt", {
      header: () => (
        <div className="flex justify-center items-center">
          <p> Registration Date</p>
          <img
            src={SortIcon}
            alt="Status Icon"
            className="ml-2 cursor-pointer"
            onClick={sortByDate}
          />
        </div>
      ),
      cell: (info) => {
        const value = info.renderValue();
        return value ? moment(value).format("DD-MM-YYYY HH:mm") : "N/A";
      },
    }),
    columnHelper.accessor((row) => row, {
      id: "action",
      cell: (info) => {
        // const value = info.getValue();
        const { uniqueId, nodalOfficerId, createdAt } = info.getValue();

        return (
          <div
            className="flex justify-center items-center"
            onClick={() =>
              NavigateRegulator(uniqueId, nodalOfficerId, createdAt, page)
            }
          >
            {/* <Link to={"/entitymaster/regulator/form"}> */}
            <img src={Eye} alt="Eye " className="cursor-pointer" />
            {/* </Link> */}
          </div>
        );
      },
      header: () => <span>Action</span>,
    }),
  ];

  const options = [
    { value: "pdf", label: "PDF" },
    { value: "docx", label: "DOCX" },
    { value: "image", label: "Image" },
  ];

  const [selectedOption1, setSelectedOption1] = useState<string | null>(null);
  const [selectedOption2, setSelectedOption2] = useState<string | null>(null);
  const [selectedOption3, setSelectedOption3] = useState<string | null>(null);

  const handleSetOption1 = (value: string) => {
    setSelectedOption1(value);
  };

  const handleSetOption2 = (value: string) => {
    setSelectedOption2(value);
  };

  const handleSetOption3 = (value: string) => {
    setSelectedOption3(value);
  };

  const handleSearchInput = (event: any) => {
    event?.preventDefault();
    const { value } = event?.target;
    setSearchInput(value);
    // if (value === "") {
    //   apiCall();
    // }
  };

  useEffect(() => {
    if (searchInput === "") {
      apiCall();
    }
  }, [searchInput]);

  const handleSetStatus = (option: any) => {
    setPage(1);
    setSelectedStatus(option);
    setStatusForSearch(option?.value);
  };

  const handleClickSearch = () => {
    setPage(1);
    apiCall();
  };
  useEffect(() => {
    const currentPageFromState = location?.state?.currentPage;
    console.log(currentPageFromState, "currentPageFromState");

    if (currentPageFromState) {
      apiCall();
      setPage(currentPageFromState);
    } else {
      setPage(1); // default to the first page
    }
  }, [location.state?.currentPage]);

  return (
    <div>
      <Layout
        layout={
          <div
            className="relative mx-4 xl:ml-[40px]"
            style={{ minHeight: "calc(100vh - 110px)" }}
          >
            <div className="mt-6 ">
              <EntityMasterTab />
            </div>
            <div className=" mt-2">
              <div className=" flex  space-x-2  items-center flex-wrap">
                <div className="md:w-[500px] lg:w-[600px] sm:w-[350px] w-[300px]">
                  <div className="mb-2">
                    <label
                      htmlFor="Deposit taker Search"
                      className="text-base font-normal text-gilroy-medium "
                    >
                      Regulator Search
                    </label>
                  </div>
                  <InputFields
                    onChange={handleSearchInput}
                    height="40px"
                    padding="10px"
                    placeholder="Search by Unique ID/name"
                  />
                </div>
                <div className=" flex items-center mt-7">
                  <div className=" w-44">
                    <div
                      className="relative w-full"
                      onClick={handleClickSearch}
                    >
                      <input
                        className={
                          "top-2 left-4 z-10 w-0 h-0 form-input border flex border-gray-300 text-gray-600 rounded-md focus:outline-none justify-between align-middle]"
                        }
                        type="file"
                        id="inputButton"
                      />
                      <button
                        className={
                          " top-0 w-full z-20 bg-[#1c468e] text-white form-input border px-[16px] py-[8px] flex   rounded-[8px] focus:outline-none focus:ring-1 focus:ring-gray-100 justify-center align-middle overflow-hidden"
                        }
                      >
                        <img
                          src={searchButton}
                          alt=""
                          className="mr-1 rounded-lg"
                        />
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="mt-[25px] mb-[35px] ">
                <div className="">
                  <p className="text-sm font-normal text-gilroy-medium ">
                    OR search by Status
                  </p>
                </div>
                <div className="flex items-center flex-wrap gap-4">
                  <div className="z-20">
                    <SelectButtonEntityMaster
                      setOption={handleSetStatus}
                      options={status}
                      selectedOption={selectedStatus}
                      placeholder="Status"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className=" h-screen md:h-auto sm:h-auto overflow-x-hidden overflow-y-auto">
              <div className="mb-20">
                {loader ? (
                  <LoaderSpin />
                ) : taskData?.length > 0 ? (
                  <ReactTable
                    defaultData={taskData}
                    columns={columns}
                    key={JSON.stringify(taskData)}
                  />
                ) : (
                  <div className=" flex justify-center items-center">
                    <h1>No data available</h1>
                  </div>
                )}
              </div>
              <div className="mt-10 absolute bottom-0">
                {taskData?.length > 0 && (
                  <CustomPagination
                    currentPage={page}
                    setCurrentPage={setPage}
                    totalItems={total}
                    itemsPerPage={pageSize}
                    maxPageNumbersToShow={5}
                  />
                )}
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default EntityMasterRegulator;
